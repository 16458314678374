import {
  Container, Main,
  ToggleForm,
  ToggleReset,
} from '../styled/components/Pages/Login';
import {LoginHeader} from '../components/Headers/LoginHeader';
import {LoginFooter} from '../components/Footers/LoginFooter';
import {useState} from 'react';
import {LoginResetForm} from '../components/Forms/LoginResetForm';
import {LoginForm} from '../components/Forms/LoginForm';
import {Api} from '../api';

export function Login() {
  const [resetPassword, setResetPassword] = useState();

  const handleLoginUser = async credentials => {
    const redirect_url = await Api.Users.login(credentials);

    if (redirect_url) {
      if (redirect_url === 'admin/dashboard') {
        return window.location = `${redirect_url}`;
      }

      return window.location = `${redirect_url}/map`;
    }
  };

  const handleForgotPassword = email => Api.Users.forgotPassword(email);

  const showToggle = resetPassword &&
      <ToggleForm onClick={() => setResetPassword(false)}><i
          className="fas fa-pencil-alt"/>Login
      </ToggleForm>;

  const passwordReset = !resetPassword &&
      <ToggleReset onClick={() => setResetPassword(true)}>Forgot your
        password?</ToggleReset>;

  const formControll = resetPassword
      ? <LoginResetForm onSubmit={handleForgotPassword}/>
      : <LoginForm onSubmit={handleLoginUser}/>;

  return (
      <Container>
        <LoginHeader/>
        <Main className="main">
          {showToggle}
          {formControll}
          {passwordReset}
        </Main>
        <LoginFooter/>
      </Container>
  );
}
