import React from 'react';
import {motion} from "framer-motion/dist/framer-motion";
import {textFadeTopAnimation} from "../../helpers/animations/landingAnimations";

function DemographicsItem({imgSrc, children, delay}) {

    return (
        <motion.li className="icon-list__item" initial={textFadeTopAnimation.initial}
                   whileInView={textFadeTopAnimation.inView}
                   viewport={{once: true}}
                   transition={{ease: "cubic-bezier(0.215, 0.61, 0.355, 1)", duration: 0.7, delay: `${delay}`}}
                   >
            <div className="icon ">
                <img src={imgSrc} alt=""/>
            </div>
            <p className="">{children}</p>
        </motion.li>
    );
}

export default DemographicsItem;
