import {
  PopupDescription,
  PopupTitle,
  PopupWrapper,
} from '../../styled/components/Popups/PostcodePopup';
import { useSelector} from 'react-redux';
import {updateFeaturesStyle} from '../../helpers/features/updateFeature';

export const PostcodePopup = ({onClosePopups}) => {
  const postcode = useSelector(state => state.postcodes.postcode);
  const map = useSelector(state=>state.maps.map);

  const handleClosePopup = () => {
    onClosePopups();
    updateFeaturesStyle(map);
  }

  if (!postcode)
    return null;

  return <PopupWrapper>
    <PopupTitle>
      {postcode.result.postcode}
      <i className="fas fa-times" onClick={handleClosePopup}/>
    </PopupTitle>
    <PopupDescription>
      {postcode.result.admin_district}
    </PopupDescription>
  </PopupWrapper>;
};