import axios from 'axios';

export const postcodesActionType = {
  GET_POSTCODE: 'GET_POSTCODE',
};

export const getPostcode = (payload) => ({
  type: postcodesActionType.GET_POSTCODE,
  payload,
});

export const getPostcodeAsync = (postcode) => async dispatch => {
  try {
    const {data: response} = await axios.get(`https://api.postcodes.io/postcodes/${postcode}`);

    return dispatch(getPostcode(response));
  } catch (e) {
    return dispatch(getPostcode({status: 400, result: {postcode,admin_district:'Postcode no longer exists'}}));
  }
};