import styled from 'styled-components';
import {Select as AntdSelect} from 'antd';
import {geoptimiseColor} from '../Variables/variables';

export const Select = styled(AntdSelect)`
  width: 100%;
  margin: 0 0 10px 0;
`;

export const Btn = styled.button`
  cursor: pointer;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 40px;
  padding: 10px 15px;
  color: ${geoptimiseColor};
  font-size: 16px;
  font-weight: bold;
  border: 1px solid ${geoptimiseColor};
  &:hover {
    background: ${geoptimiseColor};
    color: #fff;
    transition: 1s ease;
  }
`;