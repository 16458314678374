import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {setCustomerGeodemographicChart} from '../../store/charts';
import {
  addDataSelectionChart,
} from '../../helpers/charts/updateChart';
import {useHistory} from 'react-router-dom';
import {createGeoPieChart} from '../../helpers/charts/geoPieChart';
import {
  SelectionChartModal,
  ChartContainer,
  ChartWrapper,
  ChartTitle,
  SelectionModalTitle,
} from '../../styled/components/Charts/chart';
import {ClientsCreateForm} from '../Forms/ClientsCreateForm';

export const CustomerGeoChart = ({onCreateClients}) => {
  const customerGeoChart = useSelector(state => state.charts.customerGeoChart);
  const groupsByClients = useSelector(state => state.groups.groupsByClients);
  const clients = useSelector(state => state.clients.clients);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (!customerGeoChart || !groupsByClients.length)
      return null;

    addDataSelectionChart(customerGeoChart, groupsByClients);
  }, [groupsByClients, customerGeoChart]);

  useEffect(() => {
    if (customerGeoChart)
      return null;

    const geoChart = createGeoPieChart('customer-geodemographic-chart',
        history);

    dispatch(setCustomerGeodemographicChart(geoChart));
  }, [dispatch, customerGeoChart,history]);

  return (
      <ChartContainer>
        <SelectionChartModal visible={!clients.length}>
          <SelectionModalTitle>
            <ClientsCreateForm btnText={'Please upload your customer data'}
                               onCreateClients={onCreateClients}/>
          </SelectionModalTitle>
        </SelectionChartModal>
        <ChartTitle>
          All customer Geodemographic breakdown
        </ChartTitle>
        <ChartWrapper>
          <canvas id="customer-geodemographic-chart"/>
        </ChartWrapper>
      </ChartContainer>
  );
};