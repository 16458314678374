import {Btn, Input} from '../../styled/components/Forms/UserSearchForm';
import {useFormik} from 'formik';
import * as Yup from 'yup';

export function UserSearchForm({onSearchUser}) {
  const formik = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema: Yup.object({
      name: Yup.string(),
    }),
    onSubmit: (values) => {
      onSearchUser(values.username);
    },
  });

  const handleChangeInput = (e) => {
    formik.setFieldValue('username', e.target.value);

    if (!e.target.value) {
      onSearchUser('');
    }
  };

  return (
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Input type="search" placeholder="Username" id="username"
               value={formik.values.username}
               onChange={handleChangeInput}/>
        <Btn type="submit"><i className="fas fa-search"/></Btn>
      </form>
  );
}