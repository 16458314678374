import {
  Form,
  Label,
  Input,
  Button,
  TextError,
} from '../../styled/components/Pages/Login';
import {useFormik} from 'formik';
import * as Yup from 'yup';

export function LoginForm({onSubmit}) {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().max(30, 'Must be 30 characters or less')
          .required('Required'),
    }),
    onSubmit: (values, formikHelpers) => {
      onSubmit(values);
    },
  });

  const emailValidationError = formik.touched.email && formik.errors.email ? (
      <TextError>{formik.errors.email}</TextError>
  ) : null;

  const passwordValidationError = formik.touched.password && formik.errors.password ? (
      <TextError>{formik.errors.password}</TextError>
  ) : null;

  return (
      <Form onSubmit={formik.handleSubmit}>
        <Label htmlFor="email">Login to your account</Label>
        <Input placeholder="Email" id="email"
               value={formik.values.email}
               onChange={formik.handleChange}
               type="email"/>
        {emailValidationError}
        <Input placeholder="Password"
               id="password"
               value={formik.values.password}
               onChange={formik.handleChange}
               type="password"/>
        {passwordValidationError}
        <Button type="submit">Login</Button>
      </Form>
  );
}
