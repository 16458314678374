import {profileActionType} from './actions';

const initialState = {
  profile: null,
  visibleToPayPopup: null
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case profileActionType.GET_PROFILE:
      return {...state, profile: action.payload};

    case profileActionType.SET_VISIBLE_TO_PAY_POPUP:
      return {...state, visibleToPayPopup: action.payload}

    default:
      return state;
  }
};
