import {
  Header, WrapperContent,
} from '../../styled/components/Pages/Home';
import {HomeLogo} from '../Logos/HomeLogo';
import {HomeToggle} from '../Toggles/HomeToggle';
import {HomeNavigation} from '../Navigations/HomeNavigation';

export const HomeHeader = ({onLogout, onCreateClients}) => {
  return (
    <Header>
      <WrapperContent>
        <HomeLogo/>
        <HomeToggle/>
        <HomeNavigation
          onCreateClients={onCreateClients}
          onLogout={onLogout}
        />
      </WrapperContent>
    </Header>
  );
};
