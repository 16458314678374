import {
  CollapseWrapper, Option, OptionInput, OptionItem,
  OptionWrapper,
} from '../../styled/components/Forms/ClientsCreateForm';

export const DynamicOptions = ({formik}) => {
  const optionList = Object.entries(formik.values)
      .filter(([key]) => key !== 'options' && key !== 'clients' && key !==
          'visible');

  const handleToggleOptions = () => optionList.forEach(
      ([key]) => formik.setFieldValue(key, key));

  const options = optionList.map(([key, value]) => {
    return (
        <Option key={key}>
          <OptionItem htmlFor={key}>{key}</OptionItem>
          <OptionInput name={key}
                       value={value}
                       id={key}
                       onChange={formik.handleChange}
                       size="small"/>
        </Option>
    );
  });

  return (
      <CollapseWrapper onChange={handleToggleOptions}>
        <CollapseWrapper.Panel header="Options CSV" key="1">
          <OptionWrapper>
            {options}
          </OptionWrapper>
        </CollapseWrapper.Panel>
      </CollapseWrapper>
  );
};