import {
  Route,
} from 'react-router-dom';

export function LoginRoute({children, ...rest}) {

  return <Route
      {...rest}
      render={() => {

        return children;
      }
      }
  />;
}
