import {groupsActionType} from './actions';

const initialState = {
  groupsByPolygon: [],
  groupsByClients: []
};

export const groupsReducer = (state = initialState, action) => {
  switch (action.type) {

    case groupsActionType.GET_GROUPS_BY_POLYGON:
      return {...state, groupsByPolygon: action.payload};

    case groupsActionType.GET_GROUPS_BY_CLIENTS:
      return {...state, groupsByClients: action.payload};

    default:
      return state;
  }
};