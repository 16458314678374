import {
  ChartTitle,
  Container,
  ModalPopup,
  ModalTitle,
  Title,
} from '../../styled/components/Popups/PotentialCustomersPopup';
import {useDispatch, useSelector} from 'react-redux';
import {setDraw, setOverlay} from '../../store/maps';
import {
  setTotalCustomersChart,
} from '../../store/charts';
import {useEffect} from 'react';
import {addDataCustomersChart} from '../../helpers/charts/updateChart';
import {createTotalCustomersChart} from '../../helpers/charts/totalCustomersChart';
import {regex} from '../../helpers/regexs/regex';
import {TotalCustomersChart} from '../../styled/components/Charts/chart';
import {getGroupsByCodeAsync} from '../../store/groups';
import {getPolygon} from '../../helpers/features/getPolygon';

export const PotentialCustomersPopup = () => {
  const totalCustomersChart = useSelector(
      state => state.charts.totalCustomersChart);
  const groupsByPolygon = useSelector(state => state.groups.groupsByPolygon);
  const drawArea = useSelector(state => state.maps.draw);
  const overlays = useSelector(state => state.maps.overlays);
  const map = useSelector(state => state.maps.map);
  const source = useSelector(state => state.maps.source);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!totalCustomersChart || !groupsByPolygon.length)
      return null;

    addDataCustomersChart(totalCustomersChart, groupsByPolygon);
  }, [groupsByPolygon, totalCustomersChart]);

  useEffect(() => {
    dispatch(setTotalCustomersChart(createTotalCustomersChart()));
  }, [dispatch]);

  const handleDeleteDrawArea = () => {
    const features = source.getFeatures();

    features.forEach(feature => {
      if (feature.getGeometry().getType() !== 'Point') {
        source.removeFeature(feature);
      }
    });

    dispatch(setDraw(null));
    map.removeInteraction(drawArea);
  };

  const handleSelectOverlay = () => {
    dispatch(setOverlay('Geodemographics'));
    map.getLayers().getArray().forEach(layer => layer.get('name') ===
    'Geodemographics' ? layer.setVisible(true) : null);

    const polygon = getPolygon(map);

    if (polygon)
      dispatch(getGroupsByCodeAsync(polygon));
  };

  const totalCustomers = groupsByPolygon.length ? regex.numberPeople(
      String(groupsByPolygon.map(({number_clients}) => Number(number_clients))
          .reduce((acc, val) => acc + val))) : null;

  return (
      <Container visible={drawArea}>
        <Title>Map Selection
          <i onClick={handleDeleteDrawArea} className="fas fa-times"/></Title>
        <ModalPopup visible={!overlays.filter(
            overlay => overlay === 'Geodemographics').length}>
          <ModalTitle onClick={handleSelectOverlay}>
            Select the OAC Demographics - Groups overlays
          </ModalTitle>
        </ModalPopup>
        <ChartTitle>Total Potential Customers: {totalCustomers}</ChartTitle>
        <TotalCustomersChart id="total-customers-chart" width="200"
                             height="150"/>
      </Container>
  );
};