import {
    Btn,
    Modal,
    Form, Input, Label,
    Toggle,
    ToggleLine, ToggleRole, Container,
} from '../../styled/components/Forms/UserCreateForm';
import {TextError} from '../../styled/components/Pages/Login';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {regex} from '../../helpers/regexs/regex';
import {CloseBtn, WrapperBtn} from '../../styled/components/Common/common';
import {
    Btn as BtnControl,
} from '../../styled/components/Controls/UserControl';
import {PlanSelect} from '../Selects/PlanSelect';

export function UserCreateForm({onCreateUser}) {
    const formik = useFormik({
        initialValues: {
            visible: false,
            email: '',
            role: 'user',
            company: '',
            name: '',
            plan: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
            plan: Yup.string().required('Required'),
            name: Yup.string()
                .min(3, 'The minimum number of characters is 3')
                .max(50, 'Must be 50 characters or less')
                .required('Required'),
            company: Yup.string()
                .min(3, 'The minimum number of characters is 3')
                .max(50, 'Must be 50 characters or less')
                .required('Required'),
        }),
        onSubmit: (values, formikHelpers) => {
            formik.values.company = regex.company(formik.values.company);
            onCreateUser(values);
            formikHelpers.resetForm();
        },
    });

    const handleToggleRole = () => {
        if (formik.values.role === 'user') {
            formik.setFieldValue('role', 'admin');
            formik.setFieldValue('company', 'geoptimise');
            formik.setFieldValue('plan', 'ultimate');
        } else {
            formik.setFieldValue('role', 'user');
            formik.setFieldValue('company', '');
            formik.setFieldValue('plan', 'express');
        }
    };

    const controlPlanSelect = formik.values.role === 'user' &&
        <PlanSelect formik={formik}/>;

    const toggleRole = formik.values.role === 'user' ? 'admin' : 'user';

    const nameValidationError = formik.touched.name && formik.errors.name ? (
        <TextError>{formik.errors.name}</TextError>
    ) : null;

    const emailValidationError = formik.touched.email && formik.errors.email ? (
        <TextError>{formik.errors.email}</TextError>
    ) : null;

    return (
        <>
            <Container visible={formik.values.visible}>
                <Modal/>
                <Form onSubmit={formik.handleSubmit}>
                    <Toggle>
                        <ToggleLine/>
                        <ToggleRole
                            onClick={handleToggleRole}>{toggleRole}</ToggleRole>
                    </Toggle>
                    <WrapperBtn>
                        <CloseBtn onClick={() => formik.setFieldValue('visible', false)}/>
                    </WrapperBtn>
                    <Label htmlFor="name">Name </Label>
                    <Input type="text" id="name" value={formik.values.name}
                           onChange={formik.handleChange}/>
                    {nameValidationError}
                    <Label htmlFor="email">Email </Label>
                    <Input type="text" id="email" value={formik.values.email}
                           onChange={formik.handleChange}/>
                    {emailValidationError}
                    {controlPlanSelect}
                    <Btn type="submit">Create {formik.values.role}</Btn>
                </Form>
            </Container>
            <BtnControl onClick={() => formik.setFieldValue('visible', true)}>
                Create user
            </BtnControl>
        </>
    );
}
