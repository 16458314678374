import styled from 'styled-components';
import {geoptimiseColor} from '../Variables/variables';

export const Form = styled.form`
  opacity: 1;
  position: absolute;
  top: 30%;
  left: 50%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 40px 20px 40px;
  z-index: 6;
`;

export const WarningText = styled.p`
  color: #ff4136;
  width: 240px;
  line-height: 24px;
`;

export const Label = styled.label`
  margin: 0 0 10px 0;
  color: ${geoptimiseColor};
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  width: 100%;
  text-align: left;
`;

export const Input = styled.input`
  outline: none;
  display: block;
  width: 240px;
  height: 30px;
  border: 1px solid #d9d9d9;
  margin: 0 0 20px;
  padding: 10px 15px;
  box-sizing: border-box;
  font-weight: 400;

  &:focus {
    outline: 1px solid ${geoptimiseColor};
  }

  &:active {
    outline: 1px solid ${geoptimiseColor};
  }
`;

export const Btn = styled.button`
  cursor: pointer;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 40px;
  padding: 10px 15px;
  color: #ff4136;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #ff4136;

  &:hover {
    background: #ff4136;
    color: #fff;
    transition: 1s ease;
  }
`;

export const Line = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 3px solid rgb(91, 160, 241);
`;
