import {
  Btn,
  Form, Input, Label,
  Line, WarningText,
} from '../../styled/components/Forms/UserDeleteForm';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getUsersByCompanyAsync} from '../../store/users';
import {CloseBtn, WrapperBtn} from '../../styled/components/Common/common';
import {Modal} from 'antd';

export function UserDeleteForm({onDelete, user}) {
  const usersByCompany = useSelector(state => state.users.usersByCompany);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      visible: false,
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: (values, formikHelpers) => {
      onDelete(values.email);
      formikHelpers.resetForm();
    },
  });

  useEffect(() => {
    if (!user)
      return null;

    (async () => await dispatch(getUsersByCompanyAsync(user.company)))();
  }, [user, dispatch]);

  const controlWarningText = usersByCompany.length === 1 ?
      <WarningText>
        This action cannot be undone. This will permanently delete DB.
      </WarningText>
      : null;

  const emailValidationError = formik.touched.email && formik.errors.email ? (
      <WarningText>{formik.errors.email}</WarningText>
  ) : null;

  return (
      <>
        <Modal footer={false} closable={false} width={0}
               visible={formik.values.visible}>
          <Form onSubmit={formik.handleSubmit}>
            <Line/>
            <WrapperBtn>
              <CloseBtn onClick={() => formik.setFieldValue('visible', false)}/>
            </WrapperBtn>
            {controlWarningText}
            <Label htmlFor="email">Email </Label>
            <Input type="text" id="email" value={formik.values.email}
                   onChange={formik.handleChange}/>
            {emailValidationError}
            <Btn type="submit">Delete User</Btn>
          </Form>
        </Modal>
        <div onClick={() => formik.setFieldValue('visible', true)}>Delete</div>
      </>
  );
}
