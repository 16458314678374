export const mapsActionType = {
  SET_MAP: 'SET_MAP',
  SET_SOURCE:'SET_SOURCE',
  SET_DRAW:'SET_DRAW',
  SET_ZOOM:'SET_ZOOM',
  SET_LAYER:'SET_LAYER',
  SET_CURRENT_ZOOM_INDEX:'SET_CURRENT_ZOOM_INDEX',
  SET_CLIENT_POPUP:'SET_CLIENT_POPUP',
  SET_LAYERS_POPUP:'SET_LAYERS_POPUP',
  SET_OVERLAY_POPUP:'SET_OVERLAY_POPUP',
  SET_OVERLAY:'SET_OVERLAY',
  DELETE_OVERLAY:'DELETE_OVERLAY',
  DELETE_OVERLAY_POPUP:'DELETE_OVERLAY_POPUP'
};

export const setMap = (payload) => ({
  type: mapsActionType.SET_MAP,
  payload,
});

export const setLayer = (payload) => ({
  type: mapsActionType.SET_LAYER,
  payload,
});

export const setOverlay = (payload) => ({
  type: mapsActionType.SET_OVERLAY,
  payload,
});

export const deleteOverlay = (payload) => ({
  type: mapsActionType.DELETE_OVERLAY,
  payload,
});

export const deleteOverlayPopup = (payload) => ({
  type: mapsActionType.DELETE_OVERLAY_POPUP,
  payload,
});

export const setOverlayPopup = (payload) => ({
  type: mapsActionType.SET_OVERLAY_POPUP,
  payload,
});

export const setSource = (payload) => ({
  type: mapsActionType.SET_SOURCE,
  payload,
});

export const setDraw = (payload) => ({
  type: mapsActionType.SET_DRAW,
  payload,
});

export const setClientPopup = (payload) => ({
  type: mapsActionType.SET_CLIENT_POPUP,
  payload,
});

export const setLayersPopup = (payload) => ({
  type: mapsActionType.SET_LAYERS_POPUP,
  payload,
});

export const setZoom = (payload) => ({
  type: mapsActionType.SET_ZOOM,
  payload,
});

export const setCurrentZoomIndex = (payload) => ({
  type: mapsActionType.SET_CURRENT_ZOOM_INDEX,
  payload,
});