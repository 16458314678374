import styled from 'styled-components';

export const Input = styled.input`
  margin: 0 0 0 20px;
  width: 300px;
  padding: 5px;
  color: #000;
  font-size: 16px;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
`;

export const Btn = styled.button`
  margin:0 20px 0 0;
  padding: 5px;
  color: #000;
  font-size: 16px;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
`;
