import styled from 'styled-components';
import {Pagination} from 'antd';

export const SelectionChartModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.7);
  z-index: 4;
  display: ${({visible}) => visible ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
`;

export const ChartPagination = styled(Pagination)`
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 2100px) {
    
    & li {
      min-width: 22px;
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;

      &  .ant-pagination-item-link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
      }

      & a {
        padding: 0;
        font-size: 16px;
      }
    }
  }

  @media (max-width: 1700px) {  ;
    & li {
      min-width: 18px;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;

      &  .ant-pagination-item-link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }

      & a {
        padding: 0;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 1400px) {
    & li {
      min-width: 14px;
      width: 14px;
      height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      &  .ant-pagination-item-link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
      }

      & a {
        padding: 0;
        font-size: 10px;
      }
    }
  }
`;

export const SelectionModalTitle = styled.h1`
  color: #E0E0E0;
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;

  @media (max-width: 2300px) {
    font-size: 30px;
  }

  @media (max-width: 1500px) {
    font-size: 20px;
  }

  &:hover {
    opacity: .7;
  }
`;

export const ChartWrapper = styled.div`
  width: 95%;
  height: 90%;
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  color: #fff;
  width: 100%;
  height: 100%;
  flex: 1 0;
  overflow: hidden;
  font-size: 18px;

  &:nth-child(1) {
    border-right: 1px solid #737373;
    border-bottom: 1px solid #737373;
  }

  &:nth-child(2) {
    border-bottom: 1px solid #737373;
  }
`;

export const ChartTitle = styled.h3`
  display: flex;
  width: 100%;
  padding: 0 20px;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 2300px) {
    font-size: 16px;
  }

  @media (max-width: 1700px) {
    font-size: 14px;
  }
`;

export const TotalCustomersChart = styled.canvas`
  width: 200px;
  height: 150px;
  margin: 10px;
`;