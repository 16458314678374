import TileWMS from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';

export const createOverlayLayer = (map, name, params,extent) => {
  const geoLayer = map.getLayers()
      .getArray()
      .filter(layer => layer.get('name') === name);

  if (geoLayer.length)
    return null;

  const source = new TileWMS({
    url: `${process.env.REACT_APP_GEOSERVER}geoptimise/wms`,
    params,
    crossOrigin: 'anonymous',
  });

  const layer = new TileLayer({
    source,
    extent,
    visible: false,
    name,
    zIndex: 2,
    overlay: true,
    opacity:1
  });

  map.addLayer(layer);
};