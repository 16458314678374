import {ErrorNotificationText} from '../../styled/components/Pages/Home';

export const ErrorUploadClients = ({inactiveClients}) => {
  return inactiveClients.map(
      ({customer_id, postcode}) => (
          <ErrorNotificationText key={customer_id}>
            Client {customer_id} was not loaded because
            postcode {postcode} no longer exists.
          </ErrorNotificationText>
      ));
};