import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createCustomerSpendChart} from '../../helpers/charts/customerSpendChart';
import {setCustomerSpendChart} from '../../store/charts';
import {addDataCustomerSpendChart} from '../../helpers/charts/updateSpendChart';
import {getPostcodeAsync} from '../../store/postcodes';
import {useHistory} from 'react-router-dom';
import {
  SelectionChartModal, ChartContainer,
  ChartPagination,
  ChartWrapper,
  ChartTitle,
  SelectionModalTitle,
} from '../../styled/components/Charts/chart';
import {ClientsCreateForm} from '../Forms/ClientsCreateForm';

export const CustomerSpendChart = ({onClosePopups, onCreateClients}) => {
  const clients = useSelector(state => state.clients.clients);
  const customerSpendChart = useSelector(
      state => state.charts.customerSpendChart);
  const map = useSelector(state => state.maps.map);
  const history = useHistory();
  const dispatch = useDispatch();
  const [paginate, setPaginate] = useState({
    current: 1,
    pageSize: 10,
    total: clients.length,
    data: clients.slice(0, 10),
  });

  useEffect(() => addDataCustomerSpendChart(paginate.data), [paginate.data]);

  useEffect(() => {
        if (!map || customerSpendChart)
          return null;

        const handleClickLegend = async (postcode) => {
          await dispatch(getPostcodeAsync(postcode));
          history.push('/map');
        };

        const spendChart = createCustomerSpendChart(map, handleClickLegend,
            onClosePopups);

        dispatch(setCustomerSpendChart(spendChart));
      },
      [dispatch, map, customerSpendChart, onClosePopups, history]);

  useEffect(() => {
    setPaginate(prevState => ({
      ...prevState,
      total: clients.length,
      data: clients.slice(0, 10),
    }));
  }, [clients]);

  const handleChangePaginate = page => setPaginate(prevState => ({
    ...prevState,
    current: page,
    data: clients.slice((page - 1) * prevState.pageSize,
        page * prevState.pageSize),
  }));

  return (
      <ChartContainer>
        <SelectionChartModal visible={!clients.length}>
          <SelectionModalTitle><ClientsCreateForm
              btnText={'Please upload your customer data'}
              onCreateClients={onCreateClients}/></SelectionModalTitle>
        </SelectionChartModal>
        <ChartTitle>
          All customer spend by postcode district / year
        </ChartTitle>
        <ChartWrapper>
          <canvas id="customer-spend-chart"/>
        </ChartWrapper>
        <ChartPagination total={paginate.total}
                         showSizeChanger={false}
                         pageSize={paginate.pageSize}
                         onChange={handleChangePaginate}
                         current={paginate.current}/>
      </ChartContainer>
  );
};