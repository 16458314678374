import React from 'react';
import {motion} from "framer-motion/dist/framer-motion";
import previewStrategyImg from "../../assets/img/img-02.jpg";
import previewStrategyImg2 from "../../assets/img/landing-img5.png";
import previewStrategyPatternImg from "../../assets/img/pattern-02.svg";
import {
    imageAnimation,
    textFadeLeftAnimation,
    transitionEffect
} from "../../helpers/animations/landingAnimations";


function StrategyPreview() {
    return (
        <section>
            <div className="container">
                <div className="img-text img-text--reverse">
                    <div className="img-text__text">
                        <img className="bg-image " src={previewStrategyPatternImg} alt="pattern"/>
                        <motion.p
                            initial={textFadeLeftAnimation.initial}
                            whileInView={textFadeLeftAnimation.inView}
                            viewport={{once: true}}
                            transition={transitionEffect.transition}
                            className=""
                        >
                            Geoptimise allows you to view your customer base against the backdrop of these different variables. From here,
                            you can build a profile of where your best (and worst) customers are located.
                        </motion.p>
                        <motion.p
                            initial={textFadeLeftAnimation.initial}
                            whileInView={textFadeLeftAnimation.inView}
                            viewport={{once: true}}
                            transition={transitionEffect.transition}
                            className=""
                        >
                            Once you know where your ideal customer type is located, you can use Geoptimise to find more of the same,
                            in locations to suit your sales strategy.
                        </motion.p>
                    </div>
                    <motion.div className="img-text__img img-shadow"
                                style={{backgroundImage: `url(${previewStrategyImg2})`, backgroundPosition: 'inherit', padding: 0, overflow: 'hidden'}}>
                        <span></span>
                        <motion.div style={{width: '100%', height: '100%'}} initial={imageAnimation.initial}
                                    whileInView={imageAnimation.inView}
                                    viewport={{once: true}}></motion.div>
                    </motion.div>
                </div>
            </div>
        </section>
    );
}

export default StrategyPreview;
