import {
  Form, HelpDescription,
  Logo, ToPayPopupLinkWrapper,
  ToPayPopupLink, ToPayPopupModal
} from '../../styled/components/Forms/HelpGuideForm';

import apiClient from "../../libs/apiClient";
import {message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {setVisibleToPayPopup} from "../../store/profile";

export const ToPayPopup = () => {
  const dispatch = useDispatch();
  const visibleToPayPopup = useSelector(state => state.profile.visibleToPayPopup)

  const handleShowToPayPopup = async () => {
    try {
      const {data: url} = await apiClient.get('auth/pay');
      return window.location.replace(url.url)
    } catch (e) {
      return message.error('Internal server error.');
    }
  }

  return (
    <ToPayPopupModal
      visible={visibleToPayPopup}
      footer={null}
      width={600}
      centered={true}
      onCancel={() => dispatch(setVisibleToPayPopup(false))}
    >
      <Form>
        <Logo
          src="/geoptimise-logo-blue.png"
          alt="Logo company"
        />
        <HelpDescription>
          Your subscription has expired, click here to renew your subscription
        </HelpDescription>
        <ToPayPopupLinkWrapper>
          <ToPayPopupLink
            to={'#'}
            onClick={handleShowToPayPopup}
          >
            Proceed to payment
          </ToPayPopupLink>
        </ToPayPopupLinkWrapper>
      </Form>
    </ToPayPopupModal>
  );
}
