import iconComputer from "../../assets/img/icons/icon-computer2.svg";
import iconFunt from "../../assets/img/icons/icon-funt.svg";
import iconPeople from "../../assets/img/icons/icon-people2.svg";
import iconHome from "../../assets/img/icons/icon-home2.svg";
import iconMail from "../../assets/img/icons/icon-mail2.svg";


export const demographicsItemsContents = [
    {
        img: `${iconComputer}`,
        text: 'Internet usage',
        delay: 0.5
    },
    {
        img: `${iconFunt}`,
        text: 'Household expenditure',
        delay: 0.6
    },
    {
        img: `${iconHome}`,
        text: 'Property age',
        delay: 0.7
    },
    {
        img: `${iconPeople}`,
        text: 'Consumer vulnerability',
        delay: 0.8
    },
    {
        img: `${iconMail}`,
        text: 'Postcode',
        delay: 0.9
    },

]
