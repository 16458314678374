import {Dropdown} from 'antd';
import {ClientUploadList} from '../Lists/ClientUploadList';
import {
  UploadDropdownWrapper,
  UploadWrapper,
} from '../../styled/components/Dropdowns/UploadDropdown';
import {useDispatch, useSelector} from 'react-redux';
import {RhombusesSpinner} from '../Spinners/RhombusesSpinner';
import {setVisibleUploadPopup} from '../../store/clients';
import {setCategoryPopup} from '../../store/categories';
import {setOverlayPopup} from '../../store/maps';

export const UploadsDropdown = () => {
  const visible = useSelector(state => state.clients.visibleUploadPopup);
  const clientUploads = useSelector(state => state.clients.clientUploads);
  const categoryPopup = useSelector(state => state.categories.categoryPopup);
  const dispatch = useDispatch();

  const handleClickSpinner = () => {
    dispatch(setCategoryPopup({...categoryPopup, visible: false}));
    dispatch(setOverlayPopup({
      visible: false,
      name: null,
      description: null,
    }));
    dispatch(setVisibleUploadPopup(true));
  };

  const spinnerControl = clientUploads.filter(
    upload => upload.disabled).length && !visible ?
    <RhombusesSpinner onClick={handleClickSpinner}/> : null;

  return (
    <UploadWrapper>
      <Dropdown overlay={<ClientUploadList/>} placement="bottomCenter" arrow>
        <UploadDropdownWrapper>My Uploads</UploadDropdownWrapper>
      </Dropdown>
      {spinnerControl}
    </UploadWrapper>
  );
};
