import {useEffect} from 'react';
import Geocoder from 'ol-geocoder';
import {useDispatch, useSelector} from 'react-redux';
import {setGeocoder} from '../../store/geocoder';

export const MapGeocoder = () => {
  const map = useSelector(state => state.maps.map);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map)
      return null;

    const geocoder = new Geocoder('nominatim', {
      provider: process.env.REACT_APP_GEOCODER_PROVIDER,
      targetType: 'text-input',
      lang: 'en-US',
      countrycodes: 'gb',
      autoCompleteMinLength: 2,
      placeholder: 'Search for ...',
      limit: 5,
      debug: false,
      autoComplete: true,
      keepOpen: true,
    });

    dispatch(setGeocoder(geocoder));
    map.addControl(geocoder);
  }, [map, dispatch]);

  return null;
};