import {
  Form,
  Label,
  Input,
  Button,
  TextError,
  // TextSuccess,
} from '../../styled/components/Pages/Login';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useState} from "react";

export function LoginResetForm({onSubmit}) {
    const [resetSent, setResetSent] = useState(false)

    const formik = useFormik({
    initialValues: {
      email: '',
      success: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: values => {
      setResetSent(true);
      onSubmit(values);
      formik.setFieldValue('success', true);
    },
  });

  const emailValidationError = formik.touched.email && formik.errors.email ? (
      <TextError>{formik.errors.email}</TextError>
  ) : null;

  // const successResetPassword = formik.values.success &&
  //     <TextSuccess>
  //       Your password reset email should arrive shortly.
  //     </TextSuccess>;

  return (
      <Form onSubmit={formik.handleSubmit}>
        <Label htmlFor="email">Reset Your
          Password</Label>
        <Input placeholder="Email" id="email"
               value={formik.values.email}
               onChange={formik.handleChange}
               type="text"/>
        {emailValidationError}
        {/*{successResetPassword}*/}
        <Button className={`reset-form__btn`} type="submit">
            {resetSent ? 'Resend' : 'Send'} Password Reset Email
        </Button>
        <p className={`reset-form__info`}>Please check your spam/junk email filter</p>
      </Form>
  );
}
