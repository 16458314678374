import {Link} from 'react-router-dom';
import {Footer, FooterDescription} from '../../styled/components/Pages/Login';

export function LoginFooter() {
  return (
      <Footer>
        <FooterDescription>{new Date().getFullYear()} ©
          <a href="http://www.innovation-mapping.com" target="_blank"> Innovation Mapping Ltd</a>
        </FooterDescription>
      </Footer>
  );
}
