export const handleHover = (evt, item, legend) => {
  legend.chart.data.datasets[0].backgroundColor.forEach((color, index, colors) => {
    colors[index] = index === item.index || color.length === 9 ? color : color + '4D';
  });
  legend.chart.update();
}

export const handleLeave = (evt, item, legend) => {
  legend.chart.data.datasets[0].backgroundColor.forEach((color, index, colors) => {
    colors[index] = color.length === 9 ? color.slice(0, -2) : color;
  });
  legend.chart.update();
}

export const handleDelay = (context, delayed) => {
  let delay = 0;
  if (context.type === 'data' && context.mode === 'default' &&
      !delayed) {
    delay = context.dataIndex * 300 + context.datasetIndex * 100;
  }
  return delay;
}