import {
  BtnControl,
  IconControl,
} from '../../styled/components/Controls/MapControl';
import WKT from 'ol/format/WKT';
import {fromCircle} from 'ol/geom/Polygon';
import {getGroupsByCodeAsync} from '../../store/groups';
import {closePopup} from '../../helpers/popup/closePopup';
import Draw from 'ol/interaction/Draw';
import {setDraw} from '../../store/maps';
import {useDispatch, useSelector} from 'react-redux';
import {Circle, Polygon} from 'ol/geom';
import {usePendingPayment} from "../../hooks/usePendingPayment";
import {setVisibleToPayPopup} from "../../store/profile";

export const MapSelectionBtn = ({children}) => {
  const map = useSelector(state => state.maps.map);
  const profile = useSelector(state => state.profile.profile);
  const source = useSelector(state => state.maps.source);
  const dispatch = useDispatch();
  const pendingPayment = usePendingPayment()

  const handleDrawStart = () => {
    const features = source.getFeatures();

    features.forEach(feature => {
      if (feature.getGeometry() instanceof Polygon ||
        feature.getGeometry() instanceof Circle) {
        source.removeFeature(feature);
      }
    });
  };

  const handleDrawEnd = (event) => {
    const drawElem = event.feature.getGeometry();
    const format = new WKT();
    const geometryPolygon = drawElem instanceof Polygon ? drawElem : fromCircle(
      drawElem);
    const polygon = format.writeGeometry(
      geometryPolygon.clone().transform('EPSG:3857', 'EPSG:4326'));

    map.getLayers().getArray().forEach(layer => {
      if (layer.get('name') === 'Geodemographics' && layer.get('visible')) {
        dispatch(getGroupsByCodeAsync(polygon));
      }
    });
  };

  const handleCreateInteraction = () => {
    closePopup();

    const typeGeometry = profile.plan === 'ultimate' ? 'Polygon' : 'Circle';
    const draw = new Draw({
      source: source,
      type: typeGeometry,
    });

    dispatch(setDraw(draw));
    draw.on('drawstart', handleDrawStart);
    draw.on('drawend', handleDrawEnd);

    return map.addInteraction(draw);
  };

  const contentControl = children ? children : <IconControl
    className="fas fa-search-plus"/>;

  return (
    <>
      {pendingPayment
        ?
        <BtnControl title='Map Selection' onClick={() => dispatch(setVisibleToPayPopup(true))}>
          {contentControl}
        </BtnControl>
        :
        <BtnControl title='Map Selection' onClick={handleCreateInteraction}>
          {contentControl}
        </BtnControl>
      }
    </>
  );
};
