import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {createOverlayLayer} from '../../helpers/layers/createOverlayLayer';

export const ConsumerVulnerabilityOverlay = ({extentUnitedKingdom}) => {
  const map = useSelector(state => state.maps.map);

  useEffect(() => {
    if (!map)
      return null;

    createOverlayLayer(map, 'ConsumerVulnerability', {
      'LAYERS': 'geoptimise:cons_vul',
    }, extentUnitedKingdom);
  }, [map,extentUnitedKingdom]);

  return null;
};
