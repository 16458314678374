import styled from 'styled-components';
import {Menu} from 'antd';

import {
  tableBorderColor,
  tableColumnBackgroundColor,
} from '../Variables/variables';

export const Table = styled.table`
  width: 100%;
  background-color: #fff;
  border: 1px solid ${tableBorderColor};
  border-spacing: 0;
`;

export const Column = styled.th`
  text-align: left;
  padding: 15px;
  letter-spacing: 1.5px;
  border: 1px solid ${tableBorderColor};
  background-color: ${tableColumnBackgroundColor};
  color: #fff;
`;

export const ColumnAction = styled.th`
  padding: 15px;
  letter-spacing: 1.5px;
  border: 1px solid ${tableColumnBackgroundColor};
  background-color: ${tableColumnBackgroundColor};
  color: #fff;
`;

export const Row = styled.td`
  width: 250px;
  padding: 10px;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid ${tableBorderColor};
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.color};
`;

export const RowAction = styled.td`
  position: relative;
  text-align: center;
  background: ${props => props.theme.background};
  width: 80px;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid ${tableBorderColor};
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.color};
  z-index: 3;
  & i {
    cursor: pointer;
  }
`;

export const Item = styled(Menu.Item)`
  width: 100px;
  text-align: center;
  font-size: 14px;
`;
