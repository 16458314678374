import styled, {css} from "styled-components";
import {motion} from "framer-motion/dist/framer-motion";

export const Wrapper = styled.div`
    background-color: #5ba0f1;
    height: 100vh;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Box = styled.div`
    background-color: #ffffff;
    width: 30%;
    overflow: hidden;
    @media(max-width: 992px){
        width: 50%;
    }
    @media(max-width: 568px){
        width: 90%;
    }
`;

export const BoxHeader = styled.div`
    display: flex;
    justify-content: center;
    padding: 20px 10px;

    img {
        width: 50%;
    }

`;

export const BoxBody = styled(motion.div)`
    padding: 20px 60px;
    ${props => props.verifyInfo && css`
        display: flex;
        flex-direction: column;
        text-align: center;

        p {
            margin-bottom: 15px;
        }
        a{
            font-size: 18px;
        }
    `}
`;

export const BoxSpinner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.pending && css`
        margin-top: 100px;
    `}
`;

export const BoxImg = styled.div`
    display: flex;
    justify-content: center;
    margin: 40px 0 40px 0;
    span {
        color: green;
        svg{
            width: 100px;
            height: 100px;
        }
    }
    ${props => props.errorProps && css`
        span{
            color: red;
        }
    `}

`;
