import styled from 'styled-components';

export const CookiePopup = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 3;
  background: #ecf4fb;
  padding: 5px;
  box-shadow: -2px 5px 8px 0px black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

`;

export const CookiePopupText = styled.p`
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0;
  margin-right: 30px;
  max-width: 100%;
  color: #525970;
  @media screen and (max-width: 768px) {
    text-align: center;
    margin-right: 0;
    margin-bottom: 10px;
    padding: 0 10px;
  }
`;


export const CookiePopupButtonWrapper = styled.div`
  @media screen and (max-width: 768px) {
      display: flex;
      & button:last-child {
        margin-right: 0;
      }
  }
`;

export const CookiePopupButton = styled.button`
  color: red;
  cursor: pointer;
  background: #525970;
  padding: 10px 15px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  color: #ECF3F9;
  transition: all .5s ease;
  margin-right: 30px;  
  &:hover {
    background: #3c4253;
  }
`;