import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import {createXYZ} from 'ol/tilegrid';
import {MVT} from 'ol/format';
import Style from 'ol/style/Style';
import {Fill, Stroke} from 'ol/style';

export function GeodemographicsOverlay() {
  const map = useSelector(state => state.maps.map);
  const categories = useSelector(state => state.categories.categories);

  useEffect(() => {
    if (!map || !categories.length)
      return null;

    const geoLayer = map.getLayers()
        .getArray()
        .filter(layer => layer.get('name') === 'Geodemographics');

    if (geoLayer.length)
      return null;

    const simpleStyle = (feature) => {
      const category = categories.find(
          category => feature.get('oac_group') === category.group);

      if (!category)
        return null;

      return new Style({
        fill: new Fill({color: category.color}),
        stroke: new Stroke({color: '#cdcdcd', width: 0.1}),
      });
    };

    const source = new VectorTileSource({
      tileGrid: createXYZ({maxZoom: 19}),
      format: new MVT(),
      url: `${process.env.REACT_APP_GEOSERVER}gwc/service/tms/1.0.0/geoptimise:oac_2011_grp@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf`,
    });

    const layer = new VectorTileLayer({
      source,
      style: simpleStyle,
      visible: false,
      name: 'Geodemographics',
      overlay: true,
        zIndex: 1
    });

    map.addLayer(layer);

  }, [map, categories]);

  return null;
}
