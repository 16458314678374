import styled from 'styled-components';
import {Checkbox, Modal as AntdModal} from 'antd';
import {geoptimiseColor} from '../Variables/variables';
import {Link} from 'react-router-dom';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 50%;
  margin: 20px 20px 0 20px;
`;

export const HelpDescription = styled.p`
  margin: 20px 20px;
  font-size: 16px;
`;

export const CheckboxPopup = styled(Checkbox)`
  margin: 0 0 20px 20px;
  font-size: 16px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: #d9d9d9;
`;

export const ToPayPopupLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  & a {
     padding: 10px 90px;
     background: #d9d9d9;
  }
`;

export const Modal = styled(AntdModal)`
  & .ant-modal-body {
    padding: 0;
  }
`;

export const Btn = styled.button`
  background: transparent;
  border: none;
  border-left:1px solid #989898 ;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    color: ${geoptimiseColor};
  }
`;

export const PopupLink = styled(Link)`
  background: transparent;
  border: none;
  border-left:1px solid #989898 ;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: #000;
  &:hover {
    color: ${geoptimiseColor};
  }
`;

export const ToPayPopupLink = styled(Link)`
  background: transparent;
  border: none;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: #000;
`;

export const ToPayPopupModal = styled(AntdModal)`
  & .ant-modal-body {
    padding-bottom: 40px;
  }
`;
