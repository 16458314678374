import apiClient from '../../libs/apiClient';
import Cookies from 'universal-cookie';
import {message} from 'antd';

const cookies = new Cookies();

export const profileActionType = {
  GET_PROFILE: 'GET_PROFILE',
  SET_VISIBLE_TO_PAY_POPUP: 'SET_VISIBLE_TO_PAY_POPUP'
};

const getProfile = (payload) => ({
  type: profileActionType.GET_PROFILE,
  payload,
});

export const setVisibleToPayPopup = (payload) => ({
  type: profileActionType.SET_VISIBLE_TO_PAY_POPUP,
  payload
})

export const getProfileAsync = () => async dispatch => {
  try{
    const {data: response} = await apiClient.get('auth/profile');
    dispatch(setVisibleToPayPopup(response.to_pay_popup))
    dispatch(getProfile(response));
  } catch (e) {
    if (cookies.get('jwt')) {
      cookies.remove('jwt');
    }
  }
};

export const updateWelcomePopupAsync = (id, profileUpdate) => async dispatch => {
  try{
    const {data: response} = await apiClient.put(`users/${id}/popup`, profileUpdate);

    dispatch(getProfile(response));
  } catch (e) {
    message.error('Internal server error.');
  }
};

export const logoutUserAsync = () => async dispatch => {
  try{
      await apiClient.get('auth/logout');
  } catch (error) {
      console.error(error.message)
  } finally {
      cookies.remove('jwt', {path: '/', domain: `.${process.env.REACT_APP_DOMAIN}`})
      dispatch(getProfile(null));
  }
};
