import apiClient from '../../libs/apiClient';
import {message} from 'antd';

export const clientsActionType = {
  GET_CLIENTS: 'GET_CLIENTS',
  GET_CLIENT_UPLOADS: 'GET_CLIENT_UPLOADS',
  GET_INACTIVE_CLIENTS: 'GET_INACTIVE_CLIENTS',
  DELETE_INACTIVE_CLIENTS: 'DELETE_INACTIVE_CLIENTS',
  GET_CLIENTS_NOTIFICATIONS: 'GET_CLIENTS_NOTIFICATIONS',
  SET_CLIENT_UPLOAD: 'SET_CLIENT_UPLOAD',
  SET_UPLOADING_FILE: 'SET_UPLOADING_FILE',
  DELETE_UPLOADING_FILE: 'DELETE_UPLOADING_FILE',
  ADD_CLIENTS_BY_BATCH_ID: 'ADD_CLIENTS_BY_BATCH_ID',
  CHANGE_UPLOAD: 'CHANGE_UPLOAD',
  DELETE_CLIENT_UPLOAD: 'DELETE_CLIENT_UPLOAD',
  SET_VISIBLE_UPLOAD_POPUP: 'SET_VISIBLE_UPLOAD_POPUP',
  SET_VISIBLE_UPLOAD_ERROR_POPUP: 'SET_VISIBLE_UPLOAD_ERROR_POPUP',
};

export const getClients = (payload) => ({
  type: clientsActionType.GET_CLIENTS,
  payload,
});

export const deleteClientUpload = (payload) => ({
  type: clientsActionType.DELETE_CLIENT_UPLOAD,
  payload,
});

export const setUploadingFile = (payload) => ({
  type: clientsActionType.SET_UPLOADING_FILE,
  payload,
});

export const deleteUploadingFile = (payload) => ({
  type: clientsActionType.DELETE_UPLOADING_FILE,
  payload,
});

export const setClientUpload = (payload) => ({
  type: clientsActionType.SET_CLIENT_UPLOAD,
  payload,
});

export const changeUpload = (payload) => ({
  type: clientsActionType.CHANGE_UPLOAD,
  payload,
});

export const addClientsByBatchId = (payload) => ({
  type: clientsActionType.ADD_CLIENTS_BY_BATCH_ID,
  payload,
});

export const getClientUploads = (payload) => ({
  type: clientsActionType.GET_CLIENT_UPLOADS,
  payload,
});

export const getInactiveClients = (payload) => ({
  type: clientsActionType.GET_INACTIVE_CLIENTS,
  payload,
});

export const deleteInactiveClients = (payload) => ({
  type: clientsActionType.DELETE_INACTIVE_CLIENTS,
  payload,
});

export const getClientsNotifications = (payload) => ({
  type: clientsActionType.GET_CLIENTS_NOTIFICATIONS,
  payload,
});

export const setVisibleUploadPopup = (payload) => ({
  type: clientsActionType.SET_VISIBLE_UPLOAD_POPUP,
  payload,
});

export const setVisibleUploadErrorPopup = (payload) => ({
    type: clientsActionType.SET_VISIBLE_UPLOAD_ERROR_POPUP,
    payload
})

export const getClientsAsync = (batches = '') => async dispatch => {
  try {
    if (batches === '')
      return dispatch(getClients([]));

    const query = batches ? '?batches=' : '';
    const {data: response} = await apiClient.get(
        `clients${query}${batches ? batches : ''}`);

    return dispatch(getClients(response));
  } catch (e) {
    message.error('Internal server error.');
  }
};

export const addClientsByBatchIdAsync = (batches = '') => async dispatch => {
  try {
    const {data: response} = await apiClient.get(`clients?batches=${batches}`);

    return dispatch(addClientsByBatchId(response));
  } catch (e) {
    message.error('Internal server error.');
  }
};

export const setClientAsync = (clients) => async dispatch => {
  try {
    await apiClient.post('clients', clients);

    const file = {
      name: clients.get('clients').name,
      id: Number(clients.get('id')),
      disabled: true,
      created_at: new Date(),
    };

    return dispatch(setClientUpload(file));
  } catch ({response: {status}}) {
    switch (status) {
      case 403:
      dispatch(setVisibleUploadErrorPopup(true));
      dispatch(deleteClientUpload(Number(clients.get('id'))));
      dispatch(deleteUploadingFile(Number(clients.get('id'))));
      break;

      default:
        message.error('Internal server error.');
    }
  }
};

export const getClientUploadsAsync = () => async dispatch => {
  try {
    const {data: response} = await apiClient.get('uploads');

    dispatch(getClientUploads(response));
  } catch (e) {
    message.error('Internal server error.');
  }
};

export const getInactiveClientsAsync = () => async dispatch => {
  try {
    const {data: response} = await apiClient.get('clients/inactive');

    dispatch(getInactiveClients(response));
  } catch (e) {
    message.error('Internal server error.');
  }
};

export const deleteInactiveClientsAsync = () => async dispatch => {
  try {
    await apiClient.put('clients/inactive/checked');

    dispatch(deleteInactiveClients([]));
  } catch (e) {
    message.error('Internal server error.');
  }
};
