import {Container} from '../../styled/components/Controls/MapControl';
import {IncreaseZoomBtn} from '../Buttons/IncreaseZoomBtn';
import {DecreaseZoomBtn} from '../Buttons/DecreaseZoomBtn';
import {LayersPanelBtn} from '../Buttons/LayersPanelBtn';
import {PanMapBtn} from '../Buttons/PanMapBtn';
import {MapSelectionBtn} from '../Buttons/MapSelectionBtn';
import {MaxExtentBtn} from '../Buttons/MaxExtentBtn';
import {PreviousExtentBtn} from '../Buttons/PreviousExtentBtn';
import {NextExtentBtn} from '../Buttons/NextExtentBtn';

export const MapNavigation = () => {

  return (
    <Container>
      <IncreaseZoomBtn/>
      <DecreaseZoomBtn/>
      <LayersPanelBtn/>
      <PanMapBtn/>
      <MapSelectionBtn/>
      <MaxExtentBtn/>
      <PreviousExtentBtn/>
      <NextExtentBtn/>
    </Container>
  );
};
