import React from 'react';
import arrowRight from "../../assets/img/arrow-right.svg";
import bg from "../../assets/img/pattern.svg";
import videoMain from "../../assets/video/top-video.mp4";
import videoPoster from "../../assets/img/hero-video-poster.png";
import {Link} from "react-router-dom";


function Hero() {
    return (
        <section>
            <div className="hero">
                <div className="pattern" style={{backgroundImage: `url(${bg})`}}></div>
                <div className="hero__video">
                    <video muted autoPlay loop poster={videoPoster}>
                        <source  src={videoMain} type="video/mp4"/>
                    </video>
                </div>
                <div className="hero__content">
                    <h1 className="animate">Find more of your best customers</h1>
                    <div className="hero__explore">
                        <p className="label">Explore</p>
                        <img src={arrowRight} alt="arrow down"/>
                    </div>
                </div>
                <div className="hero__actions">
                    <Link className="btn btn--dark" to="/login">Sign in</Link>
                    <a href="https://www.innovation-mapping.com/contact-innovation-mapping/" target="_blank" rel="noopener noreferrer" className="btn">Contact</a>
                </div>
            </div>
        </section>
    );
}

export default Hero;
