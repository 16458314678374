import {
  BtnControl,
  IconControl,
} from '../../styled/components/Controls/MapControl';
import {setCurrentZoomIndex, setZoom} from '../../store/maps';
import {useDispatch, useSelector} from 'react-redux';

export const PreviousExtentBtn = () => {
  const map = useSelector(state => state.maps.map);
  const zooms = useSelector(state => state.maps.zooms);
  const currentZoomIndex = useSelector(state => state.maps.currentZoomIndex);
  const dispatch = useDispatch();

  const handlePreviousExtentZoom = () => {
    const view = map.getView();
    const lastMapZoom = view.getZoom();
    const containsCurrentZoom = zooms.some(zoom => zoom === lastMapZoom);

    if (!containsCurrentZoom) {
      dispatch(setZoom(lastMapZoom));
      view.setZoom(zooms[zooms.length - 1]);
      return dispatch(setCurrentZoomIndex(zooms.length - 1));
    }

    if (currentZoomIndex === 0) {
      view.setZoom(zooms[currentZoomIndex]);
      return null;
    }

    view.setZoom(zooms[currentZoomIndex - 1]);
    return dispatch(setCurrentZoomIndex(currentZoomIndex - 1));
  };

  return (
    <BtnControl
      title='Zoom to previous extent'
      onClick={handlePreviousExtentZoom}
    >
      <IconControl className="fas fa-arrow-circle-left"/>
    </BtnControl>
  );
};
