import {
  SpinnerCircle, SpinnerOrbit,
  SpinnerWrapper,
} from '../../styled/components/Spinners/CircleSpinner';

export const CircleSpinner = () => {
  return (
      <SpinnerWrapper>
        <SpinnerCircle/>
        <SpinnerOrbit/>
      </SpinnerWrapper>
  );
};