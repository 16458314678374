import apiClient from '../../libs/apiClient';
import {message} from 'antd';

export const usersActionType = {
    GET_USERS: 'GET_USERS',
    GET_PLANS: 'GET_PLANS',
    GET_STATUSES: 'GET_STATUSES',
    SET_USER: 'SET_USER',
    UPDATE_USER: 'UPDATE_USER',
    GET_USERS_BY_COMPANY: 'GET_USERS_BY_COMPANY',
};

export const getUsersAsync = (
    page = 1, limit = 25, name = '') => async dispatch => {
    try {
        const {data: response} = await apiClient.get(
            `users?page=${page}&limit=${limit}&name=${name}`);

        dispatch({type: usersActionType.GET_USERS, payload: response});
    } catch (e) {
        message.error('Internal server error.');
    }
};

export const getPlansAsync = () => async dispatch => {
    try {
        const {data: response} = await apiClient.get('/plans');

        dispatch({type: usersActionType.GET_PLANS, payload: response});
    } catch (e) {
        message.error('Internal server error.');
    }
};

export const getStatusesAsync = () => async dispatch => {
    try {
        const {data: response} = await apiClient.get('/statuses');

        dispatch({type: usersActionType.GET_STATUSES, payload: response});
    } catch (e) {
        message.error('Internal server error.');
    }
};

export const getUsersByCompanyAsync = (company) => async dispatch => {
    try {
        const {data: response} = await apiClient.get(`companies/${company}/users`);

        dispatch({type: usersActionType.GET_USERS_BY_COMPANY, payload: response});
    } catch (e) {
        message.error('Internal server error.');
    }
};

export const setUserAsync = (user) => async dispatch => {
    try {
        const {data: response} = await apiClient.post(`auth/register`, user);

        dispatch({type: usersActionType.SET_USER, payload: response});
    } catch (error) {
        if (error.response.data.errors) {
            return Object.values(error.response.data.errors).forEach(err => message.error(err))
        } else if (error.response.data.exception) {
            return message.error(error.response.data.exception)
        }
        return message.error('Internal server error.');
    }
};

export const deleteUserAsync = (
    email, page = 1, limit = 5) => async dispatch => {
    try {
        await apiClient.delete(`users/${email}`);
        const {data: response} = await apiClient.get(
            `users?page=${page}&limit=${limit}`);

        dispatch({type: usersActionType.GET_USERS, payload: response});
    } catch (e) {
        if (e.response.status === 403) {
            return message.error('You do not have enough rights.');
        }
        return message.error('Internal server error.');
    }
};

export const updateUserAsync = (user, updatedUser) => async dispatch => {
    try {
        const {data: response} = await apiClient.put(`users/${user}`, updatedUser);

        dispatch({type: usersActionType.UPDATE_USER, payload: response});
    } catch (e) {
        if (e.response.status === 403) {
            return message.error('You do not have enough rights.');
        }
        return message.error('Internal server error.');
    }
};
