export const addDataCustomersChart = (chart, data) => {
  if (!chart)
    return null;

  chart.data.labels = data.map(group => group.name);
  chart.data.datasets[0].data = data.map(group => group.number_clients);
  chart.data.datasets[0].backgroundColor = data.map(group => group.color);

  chart.update();
};

export const addDataSelectionChart = (chart, data) => {
  if (!chart)
    return null;

  addDataCustomersChart(chart, data);

  const allPeople = data.map(category => Number(category.number_clients))
      .reduce((acc, val) => acc + val);
  chart.options.plugins.tooltip.callbacks.label = (tooltipItem) => {
    return `${tooltipItem.label}: ${((Number(tooltipItem.parsed) /
            allPeople) *
        100).toFixed(1)}%`;
  };

  chart.update();
};

