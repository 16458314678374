import {
    NumberUpload,
    PopupDescription,
    PopupTitle,
    PopupWrapper, UploadTime,
} from '../../styled/components/Popups/UploadsPopup';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import moment from 'moment';
import {
    changeUpload, deleteUploadingFile,
    getClientsNotifications,
    getInactiveClientsAsync,
} from '../../store/clients';
import {getGroupsByClientsAsync} from '../../store/groups';
import {CircleSpinner} from '../Spinners/CircleSpinner';

export const UploadsPopup = ({onClosePopups}) => {
    const clientUploads = useSelector(state => state.clients.clientUploads);
    const visible = useSelector(state => state.clients.visibleUploadPopup);
    const uploadingFiles = useSelector(state => state.clients.uploadingFiles);
    const clients = useSelector(state => state.clients.clients);
    const [uploads, setUploads] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!clients.length || !uploadingFiles.length)
            return null;

        uploadingFiles.forEach(upload => {
            const client = clients.find(client => client.batch_id === upload.batchId);

            if (client) {
                clearInterval(upload.timerId);
                dispatch(getInactiveClientsAsync());
                dispatch(getGroupsByClientsAsync());
                dispatch(getClientsNotifications(['File uploaded successfully.']));
                dispatch(changeUpload({id: upload.batchId, disabled: false}));
                dispatch(deleteUploadingFile(upload.batchId))
            }
        })

    }, [clients, dispatch, uploadingFiles]);

    useEffect(() => {
        setUploads(clientUploads.filter(upload => upload.disabled));
    }, [clientUploads]);

    if (!uploads.length || !visible)
        return null;

    const dataUploading = uploads.map((upload, index) => {
        return (
            <PopupDescription key={upload.name + index}>
                <NumberUpload>
                    {index + 1}.
                </NumberUpload>
                {upload.name}
                <UploadTime>
                    ( {moment(upload.created_at)
                    .format('MMMM Do YYYY, h:mm:ss a')} )
                </UploadTime>
                <CircleSpinner/>
            </PopupDescription>
        );
    });

    return (
        <>
            <PopupWrapper>
                <PopupTitle>
                    Data uploading...
                    <i style={{marginLeft: '10px'}} onClick={onClosePopups} className="fas fa-times"/>
                </PopupTitle>
                {dataUploading}
            </PopupWrapper>
        </>
    );
};
