import {Checkbox, Divider} from 'antd';
import {
    Btn,
    CheckboxGroup,
    Container,
    DropdownHeader,
    FileIcon,
    LeftUploadWarning,
    TitleData,
    Wrapper,
} from '../../styled/components/Dropdowns/UploadDropdown';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {getClients, getClientsAsync} from '../../store/clients';
import {getGroupsByClients, getGroupsByClientsAsync} from '../../store/groups';
import {setVisibleToPayPopup} from "../../store/profile";
import {setLoading} from "../../store/loading";

export const ClientUploadList = () => {

    const clientUploads = useSelector(state => state.clients.clientUploads);
    const uploadingFiles = useSelector(state => state.clients.uploadingFiles);
    const profile = useSelector(state => state.profile.profile);
    const visibleToPayPopup = useSelector(state => state.profile.profile.to_pay_popup);
    const [checkedList, setCheckedList] = useState([]);
    const [checkAll, setCheckAll] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setCheckedList(prevState => [
            ...prevState,
            ...uploadingFiles.map(upload => upload.batchId)]);
    }, [uploadingFiles]);

    useEffect(() => {
        const batch_ids = localStorage.getItem('batch_ids');

        if (batch_ids === null) {
            return setCheckedList(clientUploads.map(upload => upload.id));
        }

        if (batch_ids) {
            const batches = batch_ids.split(',').map(batch => +batch);

            return setCheckedList(batches);
        }

        return setCheckedList([])
    }, []);

    if (visibleToPayPopup) {
        return dispatch(setVisibleToPayPopup(true))
    }

    const handleNumberUploads = () => {
        const daySubscription = new Date(profile.created_at).getDate();
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        const dateSubscription = new Date(`${year} ${month} ${daySubscription}`);

        return clientUploads.filter(
            client => dateSubscription <= new Date(client.created_at)).length;
    };

    const handleSubmitUploads = () => {
        dispatch(setLoading(true))
        localStorage.setItem('batch_ids', checkedList);
        if (checkedList.length) {
            dispatch(getGroupsByClientsAsync(checkedList));
            return dispatch(getClientsAsync(checkedList));
        }
        dispatch(getGroupsByClients([]));
        dispatch(getClients([]));
        dispatch(setLoading(false))
        return;
    };

    const handleCheckCheckbox = (event) => {
        const checked = checkedList.some(check => check === event.target.value);

        if (checked)
            return setCheckedList(
                checkedList.filter(id => id !== event.target.value));

        return setCheckedList([...checkedList, event.target.value]);
    };

    const handleCheckAllCheckbox = () => {
        checkAll ? setCheckedList([]) : setCheckedList(
            clientUploads.map(upload => upload.id));
        setCheckAll(!checkAll);
    };

    const usedNumberUploads = handleNumberUploads();
    const leftUploads = profile.number_uploads - usedNumberUploads;

    const plainOptions = clientUploads.map(upload => {
        return (
            <Checkbox key={upload.id} value={upload.id}
                      disabled={upload.disabled}
                      onChange={handleCheckCheckbox}>
                {upload.name}. ( {moment(upload.created_at)
                .format('MMMM Do YYYY, h:mm:ss a')} )
            </Checkbox>
        );
    });

    if (!clientUploads.length) {
        return (
            <Container>
                <FileIcon/>
                <TitleData>No Uploads</TitleData>
            </Container>
        );
    }

    return (
        <Wrapper>
            <DropdownHeader>
                <Checkbox value="all" onChange={handleCheckAllCheckbox}
                          checked={checkAll}>
                    Check all
                </Checkbox>
                <LeftUploadWarning>
                    Uploads remaining: {leftUploads}
                </LeftUploadWarning>
            </DropdownHeader>
            <CheckboxGroup value={checkedList}>
                <Divider style={{margin: '0 0 5px 0', padding: '0'}}/>
                {plainOptions}
            </CheckboxGroup>
            <Divider style={{margin: '5px 0', padding: '0'}}/>
            <Btn size="small" type="primary" htmlType="submit"
                 onClick={handleSubmitUploads}>
                Update Map and Dashboard
            </Btn>
        </Wrapper>
    );
};
