import React from 'react';
import {motion} from "framer-motion/dist/framer-motion";
import {
    textFadeLeftAnimation,
    textFadeRightAnimation,
    transitionEffect
} from "../../helpers/animations/landingAnimations";

function FeatureItem({imgSrc, title, children, animation}) {
    return (
        <motion.li className="feature-list__item">
            <motion.div initial={animation ? textFadeLeftAnimation.initial : textFadeRightAnimation.initial}
                        whileInView={animation ? textFadeLeftAnimation.inView : textFadeRightAnimation.inView}
                        transition={transitionEffect.transition}
                        viewport={{once: true}}>
                <div className="icon ">
                    <img src={imgSrc} alt=""/>
                </div>
                <h4 className="">{title}</h4>
                <p className="sml">{children}</p>
            </motion.div>
        </motion.li>
    );
}

export default FeatureItem;
