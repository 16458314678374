import {Circle, Polygon} from 'ol/geom';
import WKT from 'ol/format/WKT';
import {fromCircle} from 'ol/geom/Polygon';

export const getPolygon = (map) => {
  const vectorLayer = map.getLayers().getArray().find(layer => layer.get('name') === 'VectorLayer');
  const features = vectorLayer.getSource().getFeatures();
  const polygon = features.find(feature => feature.getGeometry() instanceof Polygon)
  const format = new WKT();

  if (polygon)
    return format.writeGeometry(polygon.getGeometry().clone().transform('EPSG:3857', 'EPSG:4326'));

  const circle = features.find(feature => feature.getGeometry() instanceof Circle)
  const geometryPolygon = fromCircle(circle.getGeometry());

  return format.writeGeometry(geometryPolygon.clone().transform('EPSG:3857', 'EPSG:4326'));
};