import {ModalForm, ModalFormWrapper} from '../../styled/components/Popups/HelpPopup';
import {useState} from "react";
import {PopupDescription, PopupTitle} from "../../styled/components/Popups/HelpPopup";

export const HelpPopup = ({btnText}) => {
  const [visible, setVisible] = useState(false)

  const handleVisible = () => {
    setVisible(!visible)
  }

  return (
    <>
      <ModalForm
        visible={visible}
        centered={true}
        width={'1000px'}
        onCancel={() => setVisible(!visible)}
        footer={null}
      >
        <ModalFormWrapper>
          <PopupTitle>Having trouble getting started?</PopupTitle>
          <PopupDescription>
            Geoptimise consists of a map and a dashboard. The most important feature of Geoptimise is that it allows you
            to upload your own customer data. This involves uploading a CSV file, so that your data can be plotted onto
            the map and the dashboard. If you’re having trouble doing so, please consult the FAQs below or
            contact us via <a href="mailto:emailus@innovation-mapping.com.">emailus@innovation-mapping.com.</a>
          </PopupDescription>
          <PopupDescription>
            The dashboard displays your data via three different charts:
            <ol>
              <li>Your customer’s sales by year and by postcode (top left)</li>
              <li>Your customers by which Consumer Cluster category they fall into (top right)</li>
              <li>Your customers ranked into Bronze, Silver, Gold and Platinum quartiles according to their spend with
                you
                (bottom left)
              </li>
            </ol>
          </PopupDescription>
          <PopupDescription>
            The final chart on the bottom right depicts Consumer Cluster categories of areas that you select on the map.
            Once you have explored the map and dashboard in the context of your best customers, you can select custom
            areas
            of the map for
            similar pockets of consumers. The results of these searches appear in the Pie Chart on the bottom right of
            the
            dashboard.
          </PopupDescription>
          <PopupDescription>
            For example, you may find that the majority of your best sales are by consumers in the “Semi-Detached
            Suburbia”
            category; with the radar plot depicting that these are your ‘Platinum’ customers in terms of spend.
          </PopupDescription>
          <PopupDescription>
            The map allows you to interrogate your customer base spatially and against the backdrop of a number of
            different
            overlays, such as Average
            weekly household expenditure, Property Age or by Internet User Classification*.
          </PopupDescription>
          <PopupDescription>
            You can also control the level of detail on the map via the dashboard. By clicking on a postcode on the
            x-axis
            of the first graph, the corresponding record that falls within that postcode will be immediately highlighted
            on
            the map. Also, by clicking on a text description from one of the pie charts (e.g. ‘Out in the Sticks’),
            the map will be instantly filtered to show only those areas on the map.
          </PopupDescription>
          <span>* Subscription to Ultimate version required for these 3 overlays</span>
          <PopupTitle>Frequently Asked Questions</PopupTitle>
          <h3>What are “Consumer Clusters”?</h3>
          <PopupDescription>
            Consumer Clusters are areas of the country that contain similar types of resident. The clusters themselves
            are
            derived from the Census that is carried out every ten years in the UK, and compiled by the Office for
            National
            Statistics (ONS).
            The raw data provided by the ONS (including the descriptions of each cluster) have been processed and
            developed
            by Innovation Mapping
            Ltd and are unique to Geoptimise.
          </PopupDescription>
          <h3>What is a CSV?</h3>
          <PopupDescription>
            A CSV is a comma separated variable file, that can be opened and edited in MS Excel.
            Geoptimise uses CSVs to add your customer data to the map.
          </PopupDescription>
          <h3>What data should my CSV file contain?</h3>
          <PopupDescription>
            Your CSV needs to be in a consistent format in order to upload correctly. It should contain your customer
            IDs
            (if you have them)
            as well as your customer sales values by year and by postcode. A sample CSV file can be
            downloaded from <a href="/csv_example.csv">here</a> or from the upload window of the application.
          </PopupDescription>
          <h3>Can the CSV contain Personally Identifiable Information (PII)?</h3>
          <PopupDescription>
            No. Geoptimise adds your data to the map at unit postcode only (SW1A 1AA). Please note that no name, address
            or
            personally identifiable information (PII) is stored or processed by Geoptimise.
          </PopupDescription>
          <h3>How is my data kept safe from other companies e.g. my competitors?</h3>
          <PopupDescription>
            Geoptimise has been built with security as a paramount consideration. Geoptimise uses “container”
            technology,
            as described by IBM at this
            link <a target="_blank"
                    href="https://www.ibm.com/uk-en/cloud/learn/containerization">https://www.ibm.com/uk-en/cloud/learn/containerization</a>
          </PopupDescription>
          <span>Security: The isolation of applications as containers inherently prevents the invasion of malicious code from
        affecting other containers or the host system. Additionally, security permissions can be defined to automatically block
        unwanted components from entering containers or limit communications with unnecessary resources.</span>
          <PopupDescription>
            Your data, even though it contains no PII as described above, is completely isolated in its own
            containerised,
            unique database instance.
          </PopupDescription>
          <h3>How are my customers added to the map?</h3>
          <PopupDescription>
            Your customers are will be located at the centroid of their individual unit postcode, using the data from
            your
            CSV file.
          </PopupDescription>
          <h3>What is a UNIT postcode?</h3>
          <PopupDescription>
            A UNIT postcode is a geographical reference held by every UK household, such as SW1A 1AA. Each unit postcode
            in
            the UK
            contains on average 15 properties, although in densely populated areas this can be as many as 100.
          </PopupDescription>
          <h3>
            How are my customers ranked into Platinum, Gold, Silver and Bronze?
          </h3>
          <PopupDescription>
            Geoptimise calculates the total spend of your customers over the number of years for which you provide in
            the
            CSV file.
            The 4 quartiles of Platinum, Gold, Silver and Bronze are calculated based upon this total spend. Platinum
            customers are those
            that fall into the highest 25% in terms of the amount they have spent with you, followed by Gold, Silver and
            then finally Bronze
            representing the lowest 25%. Each customer is represented by an icon on the map in one of these four
            colours.
          </PopupDescription>
        </ModalFormWrapper>
      </ModalForm>
      <div onClick={handleVisible}>
        {btnText}
      </div>
    </>
  );
};
