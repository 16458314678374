import Chart from 'chart.js/auto';
import {fromLonLat} from 'ol/proj';
import {updateFeaturesStyle} from '../features/updateFeature';
import {createCustomerPopup} from '../popup/createCustomerPopup';

export const createCustomerSpendChart = (map, onClickLegend,onClosePopups) => {
  const ctx = document.getElementById('customer-spend-chart')
      .getContext('2d');

  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels: ['SN14 6NU', 'SN12 7LJ', 'SN17 2NB', 'SN13 8DZ'],
      datasets: [
        {
          label: ['Sum of Y1'],
          data: [123, 174, 142, 113],
          backgroundColor: '#4472c4',
        },
        {
          label: ['Sum of Y2'],
          data: [324, 254, 311, 298],
          backgroundColor: '#ed7d31',
        },
        {
          label: ['Sum of Y3'],
          data: [541, 522, 567, 511],
          backgroundColor: '#a5a5a5',
        },
        {
          label: ['Sum of Y4'],
          data: [441, 486, 456, 481],
          backgroundColor: '#ffc000',
        },
        {
          label: ['Sum of Y5'],
          data: [241, 158, 423, 683],
          backgroundColor: '#5b9bd5',
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
          labels: {
            boxWidth: 16,
            padding: 16,
            font: {
              size: 16,
            },
          },
        },
      },
    },
    plugins: [
      {
        id: 'chartLegend',
        afterEvent(chart, args, options) {
          if (args.event.type !== 'click')
            return null;

          const xAxis = chart.scales.x;
          const x = args.event.x;
          const index = xAxis.getValueForPixel(x);
          const client = chart.data.clients.find(
              client => client.postcode === chart.data.labels[index]);

          if (!client)
            return null;

          onClosePopups()
          createCustomerPopup(client.customer_id);
          updateFeaturesStyle(map, client);
          map.getView().setCenter(fromLonLat(client.location.coordinates));
          map.getView().setZoom(14);
          map.getLayers().getArray().forEach(layer => {
            if(layer.get('name') === 'Geodemographics_legend'){
              map.removeLayer(layer);
            }
          });
          onClickLegend(client.postcode);
        },
      }],
  });
};