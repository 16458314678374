import {
    Input,
    Label,
    RegexText,
    Select,
} from '../../styled/components/Forms/UserCreateForm';
import {regex} from '../../helpers/regexs/regex';
import {TextError} from '../../styled/components/Pages/Login';
import {useSelector} from "react-redux";

export const PlanSelect = ({formik}) => {
    const plans = useSelector(state => state.users.plans);

    const planError = formik.errors.plan && formik.touched.plan &&
        <TextError>{formik.errors.plan}</TextError>
    const companyError = formik.touched.company && formik.errors.company ? (
        <TextError>{formik.errors.company}</TextError>
    ) : null;

    return (
        <>
            <Label htmlFor="plan">Plan </Label>
            {
                plans.length &&
                <Select
                    id="plan"
                    value={formik.values.plan || null}
                    placeholder={'select'}
                    onChange={(value) => formik.setFieldValue('plan', value)}>
                    {plans.map(plan =>
                        <Select.Option
                            value={plan.name}
                            key={plan.id}
                        >
                            {plan.name}
                        </Select.Option>
                    )}
                </Select>
            }
            {planError}

            <Label htmlFor="company">Company</Label>
            <Input type="text" id="company" value={formik.values.company}
                   onChange={formik.handleChange}/>
            {companyError}
            <Label>Converted name company</Label>
            <RegexText>{regex.company(formik.values.company)}</RegexText>
        </>
    );
};
