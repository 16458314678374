import {Dropdown, Menu} from 'antd';
import {Item} from '../../styled/components/Dashboards/AdminDashboard';
import {EllipsisOutlined} from '@ant-design/icons';
import {UserDeleteForm} from '../Forms/UserDeleteForm';
import {UserStatusForm} from '../Forms/UserStatusForm';
import {UserPlansForm} from '../Forms/UserPlansForm';

export const ActionsDropdown = ({onDelete, onUpdate, role ,user}) => {
  const planControl = role !== 'admin' ?
      <Item key="3"> <UserPlansForm onUpdate={onUpdate} user={user}/></Item>
      : null;

  return (
      <Dropdown overlay={
        <Menu style={{width: '100px'}}>
          <Item  key="1"> <UserDeleteForm user={user} onDelete={onDelete} /></Item>
          <Item key="2"><UserStatusForm onUpdate={onUpdate} user={user} /></Item>
          {planControl}
        </Menu>
      } placement="bottomCenter" trigger={['hover']}>
        <EllipsisOutlined style={{cursor: 'pointer', fontSize: '32px'}}/>
      </Dropdown>
  );
};