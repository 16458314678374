import {
  Route,
} from 'react-router-dom';

export function LandingRoute({children, ...rest}) {

  return <Route
      {...rest}
      render={() => {
        return children;
      }
      }
  />;
}
