import {
  Form, ModalForm,
} from '../../styled/components/Forms/ClientsCreateForm';
import {DynamicOptions} from '../Options/DynamicOptions';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {ClientsDragger} from '../DragAndDrops/ClientsDragger';
import {DownloadCSV} from '../Links/DownloadCSV';
import Papa from 'papaparse';
import {TextError} from '../../styled/components/Pages/Login';
import {useDispatch, useSelector} from 'react-redux';
import {
  addClientsByBatchIdAsync, setUploadingFile,
} from '../../store/clients';
import {setVisibleToPayPopup} from "../../store/profile";
import {setLoading} from "../../store/loading";

export const ClientsCreateForm = ({onCreateClients, btnText}) => {
  const clientUploads = useSelector(state => state.clients.clientUploads);
  const visibleToPayPopup = useSelector(state => state.profile.profile.to_pay_popup);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      visible: false,
      clients: null,
      options: [],
      customer_id: 'customer_id',
      postcode: 'postcode',
      year_1: 'year_1',
      year_2: 'year_2',
      year_3: 'year_3',
      year_4: 'year_4',
      year_5: 'year_5',
    },
    validationSchema: Yup.object({
      clients: Yup.mixed()
          .test('File check', 'Please add clients file.', (file) => {
            return file !== null;
          }).test('File type', 'The file must be in CSV format.', (file) => {
            if (file) {
              const dotIndex = file.name.lastIndexOf('.');

              return file.name.substring(dotIndex) === '.csv';
            }
          }),
      options: Yup.array()
          .test('Options check', 'The fields do not match the options.',
              (options) => {
                const parseOptions = options.map(
                    option => option.toLowerCase());

                if (!parseOptions.length)
                  return true;

                return Object.values(formik.values)
                    .filter(option => typeof option === 'string')
                    .map(option => option.toLowerCase())
                    .every((option) => parseOptions.includes(option));
              }),
    }),
    onSubmit: (values) => {
      dispatch(setLoading(true))
      const formData = new FormData();
      const batchIds = localStorage.getItem('batch_ids') ? localStorage.getItem(
          'batch_ids').split(',') : [];
      const batchId = clientUploads.length ? clientUploads.length + 1 : 1;
      const timerId = setInterval(() => {
        dispatch(addClientsByBatchIdAsync(batchId));
      }, process.env.REACT_APP_CHECK_TIMEOUT_UPLOAD);

      dispatch(setUploadingFile({batchId, timerId}));
      formData.append('id', batchId);
      Object.entries(values).filter(([key]) => key !== 'options')
          .forEach(([key, value]) => formData.append(key, value));

      onCreateClients(formData);
      formik.setFieldValue('visible', false);
      return localStorage.setItem('batch_ids', [...batchIds, batchId]);
    },
  });

  const handleParseFile = file => {
    Papa.parse(file, {
      complete: data => formik.setFieldValue('options', data.data[0]),
    });

    return false;
  };

  const handleRemoveFile = () => {
    formik.setFieldValue('clients', null);
    formik.setFieldValue('options', []);
  };

  const handleChangeDragger = ({file, fileList}) => {
    if (fileList.length) {
      formik.setFieldValue('clients', file);
    }
  };

  const handleVisible = () => {
    if (visibleToPayPopup) {
      return dispatch(setVisibleToPayPopup(true))
    }
    formik.setFieldValue('visible', true)
  }

  const clientsValidationError = formik.touched.clients && formik.errors.clients ? (
      <TextError>{formik.errors.clients}</TextError>
  ) : <TextError>{formik.errors.options}</TextError>;

  return (
      <>
        <ModalForm visible={formik.values.visible} title={'Import CSV'}
               cancelButtonProps={{
                 type: 'danger',
               }}
               onOk={() => formik.handleSubmit()}
               centered={true}
               onCancel={() => formik.setFieldValue('visible', false)}>
          <Form>
            <ClientsDragger onRemove={handleRemoveFile}
                            beforeUpload={handleParseFile}
                            onChange={handleChangeDragger}/>
            <DynamicOptions formik={formik}/>
            <DownloadCSV/>
            {clientsValidationError}
          </Form>
        </ModalForm>
        <div onClick={handleVisible}>
          {btnText}
        </div>
      </>
  );
};
