import {Container, Description, Title} from '../styled/components/Pages/NotFound';
import {Link} from 'react-router-dom';

export function NotFound() {
  return (
      <Container>
        <Title>404</Title>
        <Description>
          I'm afraid you've found a page that doesn't exist on Geoptimise. That
          can
          happen when you follow a link to something that has since been
          deleted. Or the link was incorrect to begin with.
        </Description>
        <Description>Sorry about that. We've logged the error for review, in
          case it's our
          fault.</Description>
        <Link to="/map">Go to the homepage</Link>
      </Container>
  );
}