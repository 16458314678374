import {Header} from '../../styled/components/Pages/Admin';
import {AdminLogo} from '../Logos/AdminLogo';
import {AdminNavigation} from '../Navigations/AdminNavigation';

export function AdminHeader({onLogout}) {
  return (
      <Header>
        <AdminLogo/>
        <AdminNavigation onLogout={onLogout}/>
      </Header>
  );
}