import apiClient from '../../libs/apiClient';
import {message} from 'antd';
export const groupsActionType = {
  GET_GROUPS_BY_POLYGON:'GET_GROUPS_BY_POLYGON',
  GET_GROUPS_BY_CLIENTS:'GET_GROUPS_BY_CLIENTS'
}

export const getGroupsByPolygon = (payload) =>({
  type: groupsActionType.GET_GROUPS_BY_POLYGON,
  payload
})

export const getGroupsByClients = (payload) =>({
  type: groupsActionType.GET_GROUPS_BY_CLIENTS,
  payload
})

export const getGroupsByCodeAsync = (polygon) => async dispatch =>{
  try {
    const {data: response} = await apiClient.get(`groups?polygon=${polygon}`);

    return dispatch(getGroupsByPolygon(response));
  } catch (e) {
    message.error('Internal server error.');
  }
}

export const getGroupsByClientsAsync = (batches = '') => async dispatch =>{
  try {
    const query = batches ? '?batches=' : '';
    const {data: response} = await apiClient.get(`groups/clients${query}${batches}`);

    return dispatch(getGroupsByClients(response));
  } catch (e) {
    message.error('Internal server error.');
  }
}