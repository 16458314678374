export const clearGeocoderMarkers = (source) => {
  if (source.getFeatures().length <= 1)
    return null;

  source.getFeatures().forEach((feature, index) => {
    if (index !== 0)
      return null;

    source.removeFeature(feature);
  });
};