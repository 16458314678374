import {User} from '../Users/User';
import {
    Column,
    ColumnAction,
    Table,
} from '../../styled/components/Dashboards/AdminDashboard';
import {useSelector} from 'react-redux';

export function UserList({onDeleteUser, onUpdateUser}) {
    const users = useSelector(state => state.users.users);

    console.log(users)

    return (
        <Table>
            <thead>
            <tr>
                <Column>Name</Column>
                <Column>Company</Column>
                <Column>Role</Column>
                <Column>Status</Column>
                <Column>Plan</Column>
                <Column>E-mail</Column>
                <Column>Last Login</Column>
                <Column>Duration</Column>
                <ColumnAction>Action</ColumnAction>
            </tr>
            </thead>
            <tbody>
            {users.map(user =>
                <User
                    onUpdateUser={onUpdateUser}
                    onDeleteUser={onDeleteUser}
                    key={user.email} user={user}
                />
            )}
            </tbody>
        </Table>
    );
}
