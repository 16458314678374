import {useEffect} from 'react';
import {Overlay} from 'ol';
import {useDispatch, useSelector} from 'react-redux';
import {setGeocoderPopup} from '../../store/geocoder';
import {GeocoderCard} from '../Cards/GeocoderCard';
import {clearGeocoderMarkers} from '../../helpers/geocoder/clearGeocoderMarkers';

export const GeocoderPopup = () => {
  const map = useSelector(state => state.maps.map);
  const geocoderPopup = useSelector(state => state.geocoder.geocoderPopup);
  const geocoder = useSelector(state => state.geocoder.geocoder);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!geocoder || !geocoderPopup.overlay)
      return null;

    geocoder.on('addresschosen', evt => {
      const source = geocoder.getSource();

      clearGeocoderMarkers(source);
      geocoderPopup.overlay.setPosition(evt.coordinate);
      dispatch(setGeocoderPopup({
        visible: true,
        address: evt.address.original.formatted,
      }));
    });
  }, [geocoder, geocoderPopup,dispatch]);

  useEffect(() => {
    if (!map)
      return null;

    if (geocoderPopup.overlay) {
      return null;
    }

    const element = document.getElementById('geocoder-popup');
    const overlay = new Overlay({
      element,
      positioning: 'bottom-center',
      stopEvent: false,
    });

    dispatch(setGeocoderPopup({overlay}));
    map.addOverlay(overlay);
  }, [map, dispatch, geocoderPopup.overlay]);

  return (
      <div id="geocoder-popup">
        <GeocoderCard/>
      </div>
  );
};