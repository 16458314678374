import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

export function OrdnanceSurveyLayer() {
  const map = useSelector(state => state.maps.map);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map)
      return null;

    const source = new TileWMS({
      url: `${process.env.REACT_APP_GEOSERVER}geoptimise/wms`,
      params: {
        'LAYERS': 'geoptimise:OS_zoomstack_light',
      },
      crossOrigin: 'anonymous',
    });

    const layer = new TileLayer({
      source,
      extent: [-962914.402197115, 6422873.912961165,196246.90796871722, 8593922.28854722],
      visible: true,
      name: 'OrdnanceSurveyBasemap',
      layer:true
    });


    map.addLayer(layer);
  }, [map, dispatch]);

  return null;
}