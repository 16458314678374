import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {createOverlayLayer} from '../../helpers/layers/createOverlayLayer';

export const PropertyAgeOverlay = ({extentUnitedKingdom}) => {
  const map = useSelector(state => state.maps.map);

  useEffect(() => {
    if (!map)
      return null;

    createOverlayLayer(map, 'PropertyAge', {
      'LAYERS': 'geoptimise:prop_lsoa',
    }, extentUnitedKingdom);
  }, [map,extentUnitedKingdom]);

  return null;
};