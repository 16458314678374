import styled from 'styled-components';
import {geoptimiseColor} from '../Variables/variables';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-family: "Ruda", Arial, Verdana, Helvetica, sans-serif;
`;

export const Btn = styled.button`
  margin: 0 20px;
  padding: 5px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${geoptimiseColor};
  &:hover{
    box-shadow: 0 0 0 1px ${geoptimiseColor};
    transition: .2s ease;
  }
  &:active {
    transform: translateY(4px);
  }
`;

export const Results = styled.div`
  color: #fff;
  font-size: 18px;
  font-weight: bold;
`;

export const Select = styled.select`
  margin-left: 15px;
  padding: 5px;
  background-color: #fff;
  color: #666;  
  font-size: 16px;
  outline: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
`;