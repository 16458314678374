import {combineReducers} from 'redux';
import {usersReducer as users} from './users';
import {profileReducer as profile} from './profile';
import {clientsReducer as clients} from './clients';
import {mapsReducer as maps} from './maps';
import {chartsReducer as charts} from './charts';
import {categoriesReducer as categories} from './categories';
import {groupsReducer as groups} from './groups';
import {postcodesReducer as postcodes} from './postcodes';
import {geocoderReducer as geocoder} from './geocoder';
import {loadingReducer as loading} from './loading';

export const reducers = combineReducers({
    users,
    profile,
    clients,
    maps,
    charts,
    categories,
    groups,
    postcodes,
    geocoder,
    loading,
});
