import styled from 'styled-components';
import {geoptimiseColor} from '../Variables/variables';

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  font-family: "Ruda", Arial, Verdana, Helvetica, sans-serif;
  @media (max-width: 400px) {
    padding: 10px;
  }
`;

export const Header = styled.header`
  width: 15%;
  background-color: #3b3f40;
`;

export const HeaderLogo = styled.div`
  background-color: #333637;
  padding: 20px;
`;

export const HeaderImage = styled.img`
  width: 80%;
`;

export const Navigation = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

export const NavList = styled.ul`
  padding: 0;
`;

export const NavBtn = styled.div`
  padding: 20px 0;
  color: #fff;
`;

export const NavItem = styled.li`
  list-style-type: none;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;

  & a {
    text-decoration: none;
    color: #fff;
    height: 100%;
    width: 100%;
    display: block;
    padding: 20px 0;
  }

  & i {
    margin-left: 10px;
  }

  &:hover {
    background-color: ${geoptimiseColor};
  }
`;

export const Main = styled.main`
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f3f3f4;
`;

export const Wrapper = styled.div`
  width: 85%;
  background-color: #3b3f40;
  margin-top: 50px;
`;



