import {
  CollapsedMenu, CollapsedMenuWrapper,
  MenuIcon, Navigation,
  NavItem, NavList,
} from '../../styled/components/Pages/Home';
import {UploadsDropdown} from '../Dropdowns/UploadsDropdown';
import {ClientsCreateForm} from '../Forms/ClientsCreateForm';
import {ClientsNotifications} from '../Notifications/ClientsNotifications';
import {HelpPopup} from '../Popups/HelpPopup';
import {CollapsedUploadsDropdown} from '../Dropdowns/CollapsedUploadsDropdown';
import {usePendingPayment} from "../../hooks/usePendingPayment";
import {UploadDropdownWrapper} from "../../styled/components/Dropdowns/UploadDropdown";
import {useDispatch} from "react-redux";
import {setVisibleToPayPopup} from "../../store/profile";
import {DownOutlined} from "@ant-design/icons";

export const HomeNavigation = ({onLogout, onCreateClients}) => {
  const pendingPayment = usePendingPayment()
  const dispatch = useDispatch();

  const showToPayPopup = () => {
    dispatch(setVisibleToPayPopup(true))
  }

  const menu = (
    <CollapsedMenu>

      <CollapsedMenu.Item onClick={showToPayPopup}>
        <ClientsNotifications/>
      </CollapsedMenu.Item>
      <CollapsedMenu.Item>
        <ClientsCreateForm
          btnText={'Upload'}
          onCreateClients={onCreateClients}/>
      </CollapsedMenu.Item>
      {pendingPayment
        ?
        <CollapsedMenu.Item
          onClick={showToPayPopup}
        >
          <div>
            My uploads<DownOutlined/>
          </div>
        </CollapsedMenu.Item>
        :
        <CollapsedMenu.Item>
          <CollapsedUploadsDropdown/>
        </CollapsedMenu.Item>
      }
      <CollapsedMenu.Item>
        <HelpPopup
          btnText={'Help'}
        />
      </CollapsedMenu.Item>
      <CollapsedMenu.Item>
        <div onClick={onLogout}>Logout</div>
      </CollapsedMenu.Item>
    </CollapsedMenu>
  );

  return (
    <Navigation>
      <CollapsedMenuWrapper overlay={menu} placement="bottomRight">
        <MenuIcon/>
      </CollapsedMenuWrapper>
      <NavList>
        <NavItem>
          <ClientsNotifications/>
        </NavItem>
        <NavItem>
          <ClientsCreateForm
            btnText={'Upload'}
            onCreateClients={onCreateClients}
          />
        </NavItem>
        {pendingPayment
          ?
          <NavItem>
            <UploadDropdownWrapper
              onClick={showToPayPopup}
            >
              My Uploads
            </UploadDropdownWrapper>
          </NavItem>
          :
          <NavItem>
            <UploadsDropdown/>
          </NavItem>
        }
        <NavItem>
          <HelpPopup
            btnText={'Help'}
          />
        </NavItem>
        <NavItem>
          <div onClick={onLogout}>Logout</div>
        </NavItem>
      </NavList>
    </Navigation>
  );
};
