import iconPhunnel from "../../assets/img/icons/icon-phunnel.svg";
import iconHand from "../../assets/img/icons/icon-hand.svg";
import iconPig from "../../assets/img/icons/icon-pigg.svg";

export const featuresItemsContents = [
  {
    img: `${iconPhunnel}`,
    title: "Filter your customers",
    text: "The dashboard feature offers a range of data charts, each of which display your individual customer expenditure(s) combined with aspects of their lifestyle. This makes it easier to spot patterns and understand the demographic of your business.",
    animation: "left",
  },
  {
    img: `${iconHand}`,
    title: "Grow your customer base",
    text: "By recognising the similarities between customers and other people, you can target your product(s) towards those of a similar lifestyle. Our mapping tool allows you to profile your existing and best consumers, before finding more in the area(s) that suit you.",
  },
  {
    img: `${iconPig}`,
    title: "Save money",
    text: "Starting from just £42 per month under our Express programme, Geoptimise helps you get ahead of your competitors through three different types of unique package. See pricing plans and how to sign up to get started below.",
    animation: "left",
  },
];
