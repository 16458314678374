import {mapsActionType} from './actions';

const initialState = {
  map: null,
  geocoder:null,
  source: null,
  draw: null,
  layersPopup: {
    visible: false,
  },
  clientPopup: {
    overlay:null,
    clientId: null,
    orders:null,
    totalOrders: null,
    averageOrder: null,
    category: null,
    visible: false,
  },
  zooms: [8],
  currentZoomIndex: 0,
  layer: 'OrdnanceSurveyBasemap',
  overlays: [],
  overlayPopup: {
    visible: false,
    name: null,
    description: null,
  },
};

export const mapsReducer = (state = initialState, action) => {
  switch (action.type) {

    case mapsActionType.SET_MAP:
      return {...state, map: action.payload};

    case mapsActionType.SET_GEOCODER:
      return {...state, geocoder: action.payload};

    case mapsActionType.SET_LAYER:
      return {...state, layer: action.payload};

    case mapsActionType.SET_OVERLAY:
      return {...state, overlays: [...state.overlays, action.payload]};

    case mapsActionType.SET_CLIENT_POPUP:
      return {
        ...state,
        clientPopup: {...state.clientPopup, ...action.payload},
      };

    case mapsActionType.DELETE_OVERLAY:
      return {
        ...state,
        overlays: state.overlays.filter(overlay => overlay !== action.payload),
      };

    case mapsActionType.DELETE_OVERLAY_POPUP:
      return {
        ...state, overlayPopup: {
          visible: false,
          name: null,
          description: null,
        },
      };

    case mapsActionType.SET_SOURCE:
      return {...state, source: action.payload};

    case mapsActionType.SET_DRAW:
      return {...state, draw: action.payload};

    case mapsActionType.SET_CURRENT_ZOOM_INDEX:
      return {...state, currentZoomIndex: action.payload};

    case mapsActionType.SET_ZOOM:
      const currentZoom = state.zooms[state.zooms.length - 1];

      return currentZoom === action.payload ? state : {
        ...state,
        zooms: [...state.zooms, action.payload],
        currentZoomIndex: state.currentZoomIndex + 1,
      };

    case mapsActionType.SET_LAYERS_POPUP:
      return {
        ...state, layersPopup: {
          visible: action.payload,
        },
      };

    case mapsActionType.SET_OVERLAY_POPUP:
      return {
        ...state, overlayPopup: {
          visible: action.payload.visible,
          name: action.payload.name,
          description: action.payload.description,
        },
      };

    default:
      return state;
  }
};
