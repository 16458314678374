import {OrdnanceSurveyLayer} from './OrdnanceSurveyLayer';
import {AerialLayer} from './AerialLayer';
import {AerialLabelLayer} from './AerialLabelLayer';
import {VectorLayer} from './VectorLayer';

export const MapLayers = () => {
  return (
      <>
        <OrdnanceSurveyLayer/>
        <AerialLayer/>
        <AerialLabelLayer/>
        <VectorLayer/>
      </>
  )
}