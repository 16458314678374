import {useEffect} from "react";
import {logoutUserAsync} from "../../store/profile";
import {useDispatch} from "react-redux";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

const LogoutOnTimeout = ({ children }) => {
    let timer
    const dispatch = useDispatch()

    const handleLogoutTimer = () => {
        timer = setTimeout(() => {
            logoutAction();
        }, process.env.REACT_APP_TIMER_LOGOUT * 60 * 1000);

    };

    const resetTimer = () => {
        if (timer) clearTimeout(timer);
    };

    useEffect(() => {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer();
                handleLogoutTimer();
            });
        });
    }, []);

    const logoutAction = () => {
        dispatch(logoutUserAsync())
    };

    return children;
};

export default LogoutOnTimeout;
