import {loadingActionType} from "./actions";

const initialState = {
    loading: false
};

export const loadingReducer = (state = initialState, action) => {
    switch (action.type) {

        case loadingActionType.SET_LOADING:
            console.log('working',action.payload)
            return {...state, loading: action.payload};
        default:
            return state;
    }
};
