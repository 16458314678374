import styled from 'styled-components';
import {
  Modal
} from 'antd';

export const ModalForm = styled(Modal)`
    max-width: 95vw;
    max-height: 800px;
    & .ant-modal-content {
      border-top: 5px solid rgb(91, 160, 241);
    }
    & h3 {
      margin-top: 15px;
      margin-bottom: 5px;
      line-height: 25px;
      color: #525970;
    }
`;

export const ModalFormWrapper = styled.div`
    max-height: 760px;
    overflow-y: scroll;
    padding-right: 30px;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const PopupTitle = styled.h2`
    color: rgb(91 160 241);
    margin-top: 15px;
    margin-bottom: 5px;
`;

export const PopupDescription = styled.p`
  font-size: 16px;
  font-weight: bold;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  & ol {
    counter-reset: list;
    padding-left: 20px;
  }
  & ol li {
    list-style: none;
  }
  & ol > li:before {
  content: counter(list) ") ";
  counter-increment: list;
}
`;