import {
  Route,
  Redirect,
} from 'react-router-dom';
import {useQuery} from '../hooks/useQuery';
import {useSelector} from 'react-redux';

export function ResetPasswordRoute({children, ...rest}) {
  const profile = useSelector(state => state.profile.profile);
  const query = useQuery();

  return <Route
      {...rest}
      render={({location}) => {
        if (query.get('token') && query.get('email')) {
          return children;
        }

        return <Redirect to={{
          pathname: profile ? '/' : '/login',
          state: {from: location},
        }}/>;
      }}
  />;
}