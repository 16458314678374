import styled from 'styled-components';
import {geoptimiseColor} from '../Variables/variables';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #3B3F40;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  @media (max-width: 400px) {
    padding: 10px;
  }
  & a {
    text-decoration: none;
    font-size: 18px;
    line-height: 20px;
    color: ${geoptimiseColor};
  }
`;

export const Title = styled.h1`
  font-size: 44px;
`;

export const Description = styled.p`
  padding: 0;
  font-size: 18px;
  width: 300px;
  line-height: 20px;
`;