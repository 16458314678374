import {createGlobalStyle} from 'styled-components';
import { LandingCss } from "../landing/Landing.styles";

export const GlobalStyle = createGlobalStyle`
  ${LandingCss}

    * {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        font-family: "Ruda", Arial, Verdana, Helvetica, sans-serif;
    }

    .container {
        position: relative;
        max-width: 1550px;
        margin: 0 auto;
        padding: 0 25px;
        box-sizing: border-box;
    }

    .container:after {
        content: "";
        display: table;
        clear: both;
    }

    .pattern {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: 850px;
        background-repeat: repeat;
        opacity: 0.1;
    }

    .ol-geocoder {
        position: absolute;
        width: 25em;
        height: 4.375em;
        top: .5em;
        left: calc(50% - 12.5em);
        box-sizing: border-box;

        @media (max-width: 600px) {
            width: 15em;
            left: calc(50% - 6.5em);
        }
    }

    .ol-geocoder.gcd-txt-container {
        position: absolute;
        width: 25em;
        height: 4.375em;
        top: .5em;
        left: calc(50% - 12.5em);
        box-sizing: border-box;

        @media (max-width: 600px) {
            width: 15em;
            left: calc(50% - 6.5em);
        }
    }

    .ol-geocoder .gcd-txt-control {
        position: relative;
        width: 100%;
        height: 4.375em;
        border: 1px solid #ccc;
        background-color: #fff;
        overflow: hidden;
    }

    .ol-geocoder .gcd-txt-input:focus {
        outline: none;
        box-shadow: inset 0 0 0 1px #4d90fe, inset 0 0 6px #4d90fe;
    }

    .ol-geocoder .gcd-txt-input {
        position: absolute;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 5px 30px 5px 40px;
        border: none;
        text-indent: 6px;
        background-color: transparent;
        font-family: inherit;
        font-size: 16px;
    }

    .ol-geocoder .gcd-txt-glass {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        display: inline-block;
        width: 2.5em;
        height: 100%;

        &::after {
            font-family: "Font Awesome 5 Free";
            font-weight: 600;
            content: '\\f689';
            display: flex;
            font-size: 24px;
            height: 100%;
            align-items: center;
            position: absolute;
            left: 10px;
            color: rgba(99, 106, 99, 0.8);
        }
    }

    .ol-geocoder .gcd-hidden {
        opacity: 0;
        visibility: hidden;
    }

    .ol-geocoder .gcd-txt-reset {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        width: 2.5em;
        height: 100%;
        line-height: 100%;
        border: none;
        background-color: transparent;
        display: inline-block;
        vertical-align: middle;
        outline: 0;
        cursor: pointer;
    }

    .ol-geocoder ul.gcd-txt-result {
        position: absolute;
        top: 4.575em;
        left: 0;
        width: 25em;
        max-height: 18.75em;
        white-space: normal;
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: #fff;
        border-radius: 4px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        overflow-x: hidden;
        overflow-y: auto;
        box-shadow: 0 1px 7px rgb(0 0 0 / 80%);
        transition: max-height .3s ease-in;
    }

    .ol-geocoder ul.gcd-txt-result:empty {
        display: none;
    }

    .ol-geocoder ul.gcd-txt-result > li:nth-child(odd) {
        background-color: #e0ffe0;
    }

    .ol-geocoder ul.gcd-txt-result > li {
        width: 100%;
        overflow: hidden;
        border-bottom: 1px solid #eee;
        padding: 0;
        line-height: .875rem;
    }

    .ol-geocoder ul.gcd-txt-result > li > a {
        display: block;
        text-decoration: none;
        padding: 3px 5px;

        &:hover {
            background-color: #d4d4d4
        }
    }

    .gcd-address, .gcd-road {
        font-size: .875em;
        font-weight: bold;
        color: #333;
    }

    .ol-geocoder .gcd-txt-reset:after {
        content: "\\d7";
        display: inline-block;
        color: #333;
        font-size: 2em;
        cursor: pointer;
    }

    .gcd-pseudo-rotate {
        &::after {
            animation: spin 1s linear infinite;
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .customer-chart {
        margin-top: 10px;
    }

  #gcd-input-search {
      opacity: 0;
  }

    @media screen and (min-width: 1000px) {
        .container {
            padding: 0 50px;
        }
    }

`;
