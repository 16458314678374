import styled from 'styled-components';
import {geoptimiseColor} from '../Variables/variables';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3B3F40;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  @media (max-width: 400px) {
    padding: 10px;
  }
`;

export const Form = styled.form`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  @media (max-width: 400px) {
    padding: 10px;
  }
`;

export const Label = styled.label`
  margin: 0 0 20px;
  color: ${geoptimiseColor};
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  width: 100%;
  text-align: left;
  padding-bottom: 5px;
  border-bottom:2px solid ${geoptimiseColor};
`;

export const Input = styled.input`
  outline: none;
  display: block;
  width: 240px;
  height: 40px;
  border: 1px solid #d9d9d9;
  margin: 0 0 20px;
  padding: 10px 15px;
  box-sizing: border-box;
  font-weight: 400;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  &:focus {
    outline: 1px solid ${geoptimiseColor};
  }
  &:active {
    outline: 1px solid ${geoptimiseColor};
  }
`;

export const Button = styled.button`
  cursor: pointer;
  background: ${geoptimiseColor};
  width: 240px;
  height: 40px;
  border: 0;
  padding: 10px 15px;
  color: #ffffff;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
`;