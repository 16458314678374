import styled from 'styled-components';
import {Select as AntdSelect} from 'antd';
import {geoptimiseColor} from '../Variables/variables';

export const Select = styled(AntdSelect)`
  width: 100%;
  margin: 0 0 10px 0;
`;

export const Modal = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #3B3F40;
  left: 0;
  top: 0;
  opacity: 0.8;
  z-index: 5;
`;

export const Form = styled.form`
  opacity: 1;
  position: absolute;
  top: 30%;
  left: 50%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 40px 20px 40px;
  z-index: 6;
`;

export const Label = styled.label`
  margin:0 0 10px 0;
  color: ${geoptimiseColor};
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  width: 100%;
  text-align: left;
`;

export const Btn = styled.button`
  cursor: pointer;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 40px;
  padding: 10px 15px;
  color: ${geoptimiseColor};
  font-size: 16px;
  font-weight: bold;
  border: 1px solid ${geoptimiseColor};
  &:hover {
    background: ${geoptimiseColor};
    color: #fff;
    transition: 1s ease;
  }
`;

export const Line = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 3px solid rgb(91, 160, 241);
`;
