import Chart from 'chart.js/auto';

export const createTotalCustomersChart = () => {
  const ctx = document.getElementById('total-customers-chart')
      .getContext('2d');

  return new Chart(ctx, {
    type: 'doughnut',
    data: {
      labels: [
        'No overlay on the map',
      ],
      datasets: [
        {
          data: [1],
          backgroundColor: [
            'rgb(192,192,192)',
          ],
          hoverOffset: 4,
        }],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  });
};