import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';

import {Login} from './pages/Login';
import {Admin} from './pages/Admin';
import {ResetPassword} from './pages/ResetPassword';
import {NotFound} from './pages/NotFound';
import {Home} from './pages/Home';
import {PrivateRoute} from './hoc/PrivateRoute';
import {ProvideAuth} from './hoc/ProvideAuth';
import {LandingRoute} from './hoc/LandingRoute';
import {ResetPasswordRoute} from './hoc/ResetPasswordRoute';
import Landing from "./pages/landing/Landing";
import {GlobalStyle} from './styled/Global/GlobalStyle';
import LogoutOnTimeout from "./helpers/auth/LogoutOnTimeout";
import VerifyEmail from "./pages/landing/VerifyEmail";
import {LoginRoute} from "./hoc/LoginRoute";

function App() {
    return (
        <ProvideAuth>
            <GlobalStyle/>
            <Router>
                <Switch>
                    <LandingRoute exact path="/">
                        <Landing/>
                    </LandingRoute>
                    <Route exact path="/verify">
                        <VerifyEmail/>
                    </Route>
                    <LoginRoute exact path="/login">
                        <Login/>
                    </LoginRoute>
                    <ResetPasswordRoute exact path="/reset-password">
                        <ResetPassword/>
                    </ResetPasswordRoute>
                    <PrivateRoute exact path="/admin/dashboard">
                        <Admin/>
                    </PrivateRoute>
                    <PrivateRoute exact path="/:page?">
                        <LogoutOnTimeout>
                            <Home/>
                        </LogoutOnTimeout>
                    </PrivateRoute>
                    <Route exact path="*" component={NotFound}/>
                </Switch>
            </Router>
        </ProvideAuth>
    );
}

export default App;
