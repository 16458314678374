import {
  CheckboxGroup,
  CheckboxWrapper,
  QuestionIcon,
} from '../../styled/components/Checkboxes/OverlayCheckboxGroup';
import {Checkbox} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {deleteOverlay, setOverlay, setOverlayPopup} from '../../store/maps';

export const OverlayCheckboxGroup = ({onClosePopups}) => {
  const overlays = useSelector(state => state.maps.overlays);
  const mapLayer = useSelector(state => state.maps.layer);
  const map = useSelector(state => state.maps.map);
  const profile = useSelector(state => state.profile.profile);
  const dispatch = useDispatch();

  const handleChangeOverlays = (event) => {
    if (!map)
      return null;

    const overlay = event.target.value;

    map.getLayers().getArray().forEach(layer => {
      if (layer.get('name') === 'VectorLayer' || layer.get('name') === mapLayer)
        return null;

      if (layer.get('name') === overlay) {
        if (event.target.checked) {
          layer.setVisible(true);
          dispatch(setOverlay(overlay));
        } else {
          layer.setVisible(false);
          dispatch(deleteOverlay(overlay));
        }
      }
    });
  };

  const handleClickQuestion = name => {
    dispatch(setOverlayPopup({visible: true, name}));
  };

  return (
      <CheckboxGroup value={overlays}>
          { ['express', 'pro', 'ultimate', 'evaluation'].includes(profile.plan) &&
              <>
                  <CheckboxWrapper>
                      <Checkbox
                          onClick={handleChangeOverlays}
                          value="PostcodeBoundaries"
                      >
                          Postcode sector boundaries
                      </Checkbox>
                      <QuestionIcon
                          onClick={()=>handleClickQuestion('Postcode sector boundaries')}
                          className="far fa-question-circle"
                      />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                      <Checkbox
                          onClick={handleChangeOverlays}
                          value="Geodemographics"
                      >
                          Consumer Clusters
                      </Checkbox>
                      <QuestionIcon
                          onClick={()=>handleClickQuestion('Consumer Clusters')}
                          className="far fa-question-circle"
                      />
                  </CheckboxWrapper>
              </>
          }
          { ['pro', 'ultimate'].includes(profile.plan) &&
              <>
                  <CheckboxWrapper>
                      <Checkbox
                          onClick={handleChangeOverlays}
                          value="Household"
                      >
                          Average weekly household expenditure
                      </Checkbox>
                      <QuestionIcon
                          onClick={()=>handleClickQuestion('Average weekly household expenditure')}
                          className="far fa-question-circle"/>
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                      <Checkbox
                          onClick={handleChangeOverlays}
                          value="PropertyAge"
                      >
                          Modal Property Age
                      </Checkbox>
                      <QuestionIcon
                          onClick={()=>handleClickQuestion('Modal Property Age')}
                          className="far fa-question-circle"
                      />
                  </CheckboxWrapper>
              </>
          }
          { ['ultimate'].includes(profile.plan) &&
              <>
                  <CheckboxWrapper>
                      <Checkbox
                          onClick={handleChangeOverlays}
                          value="ConsumerVulnerability"
                      >
                          Consumer Vulnerability
                      </Checkbox>
                      <QuestionIcon
                          onClick={()=>handleClickQuestion('Consumer Vulnerability')}
                          className="far fa-question-circle"
                      />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                      <Checkbox
                          onClick={handleChangeOverlays}
                          value="InternetUserClassification"
                      >
                          Internet User Classification
                      </Checkbox>
                      <QuestionIcon
                          onClick={()=>handleClickQuestion('Internet User Classification')}
                          className="far fa-question-circle"
                      />
                  </CheckboxWrapper>
              </>
          }
      </CheckboxGroup>
  )
}
