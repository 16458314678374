import {
  BtnControl,
  IconControl,
} from '../../styled/components/Controls/MapControl';
import {setZoom} from '../../store/maps';
import {setZoomByClients} from '../../helpers/map/setZoomByClients';
import {useDispatch, useSelector} from 'react-redux';
import {setVisibleToPayPopup} from "../../store/profile";
import {usePendingPayment} from "../../hooks/usePendingPayment";

export const MaxExtentBtn = () => {
  const map = useSelector(state => state.maps.map);
  const clients = useSelector(state => state.clients.clients);
  const dispatch = useDispatch();
  const pendingPayment = usePendingPayment()

  const handleMaxExtentZoom = () => {
    if (!clients.length) {
      const view = map.getView();
      const zoom = view.getMaxZoom();

      dispatch(setZoom(zoom));

      return view.setZoom(zoom);
    }

    return setZoomByClients(map, clients);
  };

  return (
    <>
      {pendingPayment
        ?
        <BtnControl
          title='Zoom to extent of uploaded data'
          onClick={() => dispatch(setVisibleToPayPopup(true))}
        >
          <IconControl className="fas fa-globe-europe"/>
        </BtnControl>
        :
        <BtnControl
          title='Zoom to extent of uploaded data'
          onClick={handleMaxExtentZoom}
        >
          <IconControl className="fas fa-globe-europe"/>
        </BtnControl>
      }
    </>
  );
};
