import React from 'react';
import { MainSpinnerWrapper } from "../../styled/components/Spinners/MainSpinner";
import { Spin } from "antd";

function MainSpinner() {

    return (
        <MainSpinnerWrapper>
            <Spin size="large" />
        </MainSpinnerWrapper>
    );
}

export default MainSpinner;
