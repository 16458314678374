import styled from 'styled-components';

export const PopupWrapper = styled.div`
  width: 200px;
  position: absolute;
  right: 50px;
  top: 50px;
  background-color: rgba(99, 106, 99, 0.9);
  color: #fff;

  @media (max-width: 1500px){
    width: 350px;
  }
  
`;

export const PopupTitle = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  background-color: rgba(80, 80, 80, 0.7);
  & i {
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
`;

export const PopupDescription = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 0 10px 10px 10px;
`;
