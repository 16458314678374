import {Dropdown} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {useState} from 'react';
import {CollapsedMenu} from '../../styled/components/Pages/Home';
import {ClientUploadList} from '../Lists/ClientUploadList';

export const CollapsedUploadsDropdown = () => {
  const [visible,setVisible] = useState(false);

  const menu = (
      <CollapsedMenu>
        <ClientUploadList />
      </CollapsedMenu>);

  return (
      <Dropdown
          overlay={menu}
          placement="bottomCenter"
          onVisibleChange={flag=>setVisible(flag)}
          visible={visible}
      >
        <div onClick={e => e.preventDefault()}>
          My uploads<DownOutlined/>
        </div>
      </Dropdown>
  )
}