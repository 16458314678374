import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {getProfileAsync} from '../store/profile';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function ProvideAuth({children}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (cookies.get('jwt')) {
        await dispatch(getProfileAsync());
       return  setIsLoading(false);
      }

      setIsLoading(false)
    })();
  });

  return !isLoading && children;
}