import {
  LayersTitle,
  MapLayersTitle, RadioWrapper,
  WrapperLayersPopup,
} from '../../styled/components/Maps/Map';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  setLayer, setLayersPopup,
} from '../../store/maps';
import {Slider} from 'antd';
import {OverlayCheckboxGroup} from '../Checkboxes/OverlayCheckboxGroup';

export const MapLayersPopup = ({onClosePopups}) => {
  const map = useSelector(state => state.maps.map);
  const visible = useSelector(state => state.maps.layersPopup.visible);
  const mapLayer = useSelector(state => state.maps.layer);
  const overlays = useSelector(state => state.maps.overlays);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map)
      return null;

    const handleChangeLayers = layer => {
      if (!layer.get('layer') || layer.get('name') === 'VectorLayer')
        return null;

      layer.get('name') === mapLayer
          ? layer.setVisible(true)
          : layer.setVisible(false);
    };

    map.getLayers().getArray().forEach(handleChangeLayers);
  }, [mapLayer, map]);

  const handleClosePopup = () => dispatch(setLayersPopup(false));

  const handleChangeMapLayers = (e) => dispatch(setLayer(e.target.value));

  const radio = [
    {label: 'Ordnance Survey Base map', value: 'OrdnanceSurveyBasemap'},
    {label: 'Aerial Photo', value: 'Aerial'},
    {label: 'Aerial With Labels', value: 'AerialWithLabelsOnDemand'},
  ];

  const handleChangeSlider = (e) => {
    map.getLayers().getArray().forEach(layer => {
      if (layer.get('overlay')) {
        layer.setOpacity(e / 100);
      }
    });
  };

  return (
      <WrapperLayersPopup visible={visible}>
        <MapLayersTitle>Map Layers
          <i className="fas fa-times" onClick={handleClosePopup}/>
        </MapLayersTitle>
        <LayersTitle>Base Layer</LayersTitle>
        <RadioWrapper options={radio} onChange={handleChangeMapLayers}
                      value={mapLayer}/>
        <LayersTitle>Overlays</LayersTitle>
        <OverlayCheckboxGroup onClosePopups={onClosePopups} />
        <Slider onChange={handleChangeSlider} defaultValue={100}
                disabled={!overlays.length}/>
      </WrapperLayersPopup>
  );
};