import React, { useState, useEffect } from "react";
import { motion } from "framer-motion/dist/framer-motion";
import {
  scalingAnimation,
  transitionEffect,
  textFadeRightAnimation,
  textFadeLeftAnimation,
} from "../../helpers/animations/landingAnimations";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import introImg1 from "../../assets/img/landing-img6.png";
import introImg2 from "../../assets/img/landing-img7.png";
import shape1 from "../../assets/img/shape-01.svg";
import { ImageBox, ImageWrapper } from "../../styled/landing/intro.styles";

function Intro() {
  return (
    <section className="bg">
      <div className="container">
        <div className="intro-section">
          <motion.div
            className="intro-section__img"
            initial={scalingAnimation.initial}
            whileInView={scalingAnimation.inView}
            viewport={{ once: true }}
            transition={{
              ease: "cubic-bezier(0.215, 0.61, 0.355, 1)",
              duration: 0.2,
              delay: 2,
            }}
          >
            <img src={shape1} alt="pattern" />
          </motion.div>
          <div className="intro-content">
            <h2 className="rule rule--colour">How it works </h2>
            <motion.p
              className="intro-p"
              initial={textFadeRightAnimation.initial}
              whileInView={textFadeRightAnimation.inView}
              viewport={{ once: true }}
              transition={transitionEffect.transition}
            >
              The UK is made up of 'pockets' of similar household types,
              according to data derived from the UK census. By combining your
              customer information with these data, Geoptimise unlocks a way to
              target specific groups of consumers with ease and reliability.
            </motion.p>
            <motion.p
              className="intro-p"
              initial={textFadeRightAnimation.initial}
              whileInView={textFadeRightAnimation.inView}
              viewport={{ once: true }}
              transition={transitionEffect.transition}
            >
              This not only eliminates blanket marketing, but it also ensures a
              more efficient sales process - Geoptimise saves you both time and
              money, simultaneously leaving you safe in the knowledge that you
              are maximising your sales and marketing efforts.
            </motion.p>
          </div>
        </div>

        <ImageWrapper>
          <Carousel
            autoPlay
            showArrows={false}
            showStatus={false}
            infiniteLoop
            interval={5000}
            showThumbs={false}
            showIndicators={false}
            transitionTime={1800}
          >
            <ImageBox>
              <img className="intro-img1" src={introImg1} alt="" />
            </ImageBox>
            <ImageBox>
              <img className="intro-img1" src={introImg2} alt="" />
            </ImageBox>
          </Carousel>
        </ImageWrapper>
      </div>
    </section>
  );
}

export default Intro;
