import {categoriesActionType} from './actions';

const initialState = {
  categories: [],
  popup: {
    name: null,
    code: null,
    visible: false,
    description: null,
    color: null,
    group: null,
    customer: null,
    batchId: null,
  },
};

export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {

    case categoriesActionType.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload.map(
            category => ({
              ...category,
              name: category.name,
              color: category.color,
              group: category.group,
              description: category.description,
            })),
      };

    case categoriesActionType.DELETE_CATEGORY_POPUP:
      return {
        ...state,
        popup: {
          name: null,
          code: null,
          visible: false,
          description: null,
          color: null,
          group: null,
          customer: null,
          batchId: null,
        },
      };

    case categoriesActionType.SET_CATEGORY_POPUP:

      return {
        ...state, popup: {
          ...state.popup,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};