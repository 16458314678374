import {
  Form,
  Label,
  Line,
} from '../../styled/components/Forms/UserDeleteForm';
import {useFormik} from 'formik';
import {Select, Btn} from '../../styled/components/Forms/UserStatusForm';
import * as Yup from 'yup';
import {CloseBtn, WrapperBtn} from '../../styled/components/Common/common';
import {Modal} from 'antd';
import {useSelector} from "react-redux";

export function UserStatusForm({onUpdate, user}) {
    const statuses = useSelector(state => state.users.statuses);

    const formik = useFormik({
    initialValues: {
      visible:false,
      status_id: '',
    },
    validationSchema: Yup.object({
      status_id: Yup.string().required(),
    }),
    onSubmit: async (values, formikHelpers) => {
      onUpdate(user.id, values);
      formikHelpers.resetForm();
    },
  });

  return (
      <>
        <Modal
            footer={false}
            closable={false}
            width={0}
            visible={formik.values.visible}
        >
        <Form onSubmit={formik.handleSubmit}>
          <Line/>
          <WrapperBtn>
            <CloseBtn onClick={() => formik.setFieldValue('visible', false)}/>
          </WrapperBtn>
          <Label htmlFor="email">Status</Label>
          <Select
              defaultValue={user.status}
              onChange={(value) => formik.setFieldValue('status_id', value)}
          >
            {statuses.map(status =>
            <Select.Option
                value={String(status.id)}
                key={status.id}
            >
                {status.name}
            </Select.Option>
            )}
          </Select>
          <Btn type="submit">Change Status</Btn>
        </Form>
        </Modal>
        <div onClick={() => formik.setFieldValue('visible', true)}>Status</div>
      </>
  );
}
