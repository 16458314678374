import React from 'react';
import {motion} from "framer-motion/dist/framer-motion";
import logo from "../assets/img/geoptimise-logo-blue.svg";
import bg from "../assets/img/pattern.svg";
import {textFadeTopAnimation, transitionEffect} from "../helpers/animations/landingAnimations";

function Footer() {
    return (
        <footer>
            <div className="pattern" style={{backgroundImage: `url(${bg})`}}></div>
            <div className="container">
                <motion.div initial={textFadeTopAnimation.initial}
                            whileInView={textFadeTopAnimation.inView}
                            viewport={{once: true}}
                            transition={transitionEffect.transition} className="logo">
                    <img src={logo} alt="Geoptimise logo"/>
                </motion.div>
                <motion.h4 initial={textFadeTopAnimation.initial}
                           whileInView={textFadeTopAnimation.inView}
                           viewport={{once: true}}
                           transition={transitionEffect.transition}>Find more of your best customers
                </motion.h4>
                <motion.div className="legal" initial={textFadeTopAnimation.initial}
                            whileInView={textFadeTopAnimation.inView}
                            viewport={{once: true}}
                            transition={transitionEffect.transition}>
                    <p className="sml center">Geoptimise is a product from <a href="https://www.innovation-mapping.com/" target="_blank" rel="noopener noreferrer">Innovation Mapping Ltd</a>
                    </p>
                </motion.div>
                <motion.p initial={textFadeTopAnimation.initial}
                          whileInView={textFadeTopAnimation.inView}
                          viewport={{once: true}}
                          transition={transitionEffect.transition} className="sml center">
                    Copyright © 2023 Innovation Mapping Ltd. All Rights Reserved
                </motion.p>
            </div>
        </footer>
    );
}

export default Footer;
