import styled from 'styled-components';
import {Checkbox} from 'antd';

export const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 10px;

  & span {
    color: #fff;
    font-size: 16px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const QuestionIcon = styled.i`
  margin: 0 10px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }
`;