import apiClient from '../libs/apiClient';
import {message} from 'antd';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const login = async credentials => {
  try {
    const {data: {access_token: token, redirect_url}} = await apiClient.post(
        'auth/login',
        credentials);

    cookies.set('jwt', token,
        {path: '/', domain: `.${process.env.REACT_APP_DOMAIN}`});

    return redirect_url;
  } catch ({response: {status}}) {
    switch (status) {
      case 400:
        message.error(
            'The email or password you entered is incorrect, please try again.',
        );
        break;

      case 403:
        message.error(
            'Your account is temporarily blocked.',
        );
        break;

      default:
        message.error('Internal server error.');
    }
  }
};

const forgotPassword = async email => {
  try {
      await apiClient.post('auth/forgot-password', email);
  } catch (e) {
    message.error('Internal server error.');
  }
};

const resetPassword = async credentials => {
  try {

      const {status} = await apiClient.post('auth/reset-password', credentials);
      if (status === 201) {
          message.success(
              'Your password has been successfully reset',
          )
          return window.location.href = '/login'
      }
  } catch ({response: {status}}) {
    switch (status) {
      case 400:
        message.error(
            'Invalid token provided.',
        );
        break;

      case 422:
        message.error(
            'The password format is invalid.',
        );
        break;

      default:
        message.error('Internal server error.');
    }
  }
};

export const Users = {
  login,
  forgotPassword,
  resetPassword,
};
