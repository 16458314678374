import React from 'react';
import {motion} from "framer-motion/dist/framer-motion";
import { featuresItemsContents } from "../../helpers/landing/featuresItemsContents";
import FeatureItem from "../../components/Landing/FeatureItem";
import {textFadeTopAnimation, transitionEffect} from "../../helpers/animations/landingAnimations";

function Features() {
    return (
        <section className="bg">
            <div className="container">
                <div className="features">
                    <motion.h2 initial={textFadeTopAnimation.initial}
                               whileInView={textFadeTopAnimation.inView}
                               viewport={{once: true}}
                               transition={transitionEffect.transition} className="rule rule--top center">Why use Geoptimise?</motion.h2>
                    <ul className="feature-list">
                        {
                            featuresItemsContents.map((item, index) => (
                                <FeatureItem key={index} imgSrc={item.img} title={item.title} animation={item.animation}>
                                    {item.text}
                                </FeatureItem>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Features;
