import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {
  createClientsMarker,
} from '../../helpers/map/createFeature';

export const ClientsMarkerList = () => {
  const clients = useSelector(state => state.clients.clients);
  const source = useSelector(state => state.maps.source);

  useEffect(() => {
    if (!source)
      return null;

    source.getFeatures().forEach(feature => {
      source.removeFeature(feature);
    });

    const geoClient = clients.map(client => {
      const totalOrder = Object.entries(client)
          .filter(([key]) => key.includes('year'))
          .map(([key, value]) => Number(value))
          .reduce((acc, order) => acc + order);

      return ({...client, total: totalOrder});
    }).sort((a, b) => b.total - a.total);

    createClientsMarker(source, geoClient);
  }, [source, clients]);

  return null;
};