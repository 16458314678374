import {store} from '../../store';

export const addDataCustomerSpendChart = (clients) => {
  const chart = store.getState().charts.customerSpendChart;
  const labels = clients.map(client => client.postcode);

  if (!chart || !clients.length)
    return null;

  const datasets = Array(chart.data.datasets.length).fill(0).map(()=>[]);

  clients.forEach(client => {
    datasets.forEach((data, index) => {
      data.push(client[`year_${index + 1}`]);
    });
  });

  chart.data.labels = labels;
  chart.data.clients = clients;

  datasets.forEach((data, index) => {
    chart.data.datasets[index].data = data;
  });
  chart.update();

};