import React from 'react';
import logo from "../assets/img/geoptimise-logo.svg";

function Header() {
    return (
        <header>
            <div className="container">
                <div className="logo">
                    <img src={logo} alt="Geoptimise logo"/>
                </div>
            </div>
        </header>
    );
}

export default Header;
