import React, {useEffect, useState} from 'react';
import Layout from "../../layout/Layout";
import Hero from "./Hero";
import Intro from "./Intro";
import Demographics from "./Demographics";
import StrategyPreview from "./StrategyPreview";
import Features from "./Features";
import Contact from "./Contact";
import Preloader from "../../components/Landing/Preloader";
// import Plans from "./Plans";
import {
  CookiePopup,
  CookiePopupButton,
  CookiePopupButtonWrapper,
  CookiePopupText
} from '../../styled/components/Popups/CookiePopup'

function Landing() {

    const [preloader, setPreloader] = useState(true);
    const [cookiePopup, setCookiePopup] = useState(true);

    const handleCookiePopup = () => {
      setCookiePopup(false)
      localStorage.setItem('cookiePopup', JSON.stringify(false))
    }

    useEffect(() => {

      JSON.parse(localStorage.getItem('cookiePopup')) !== null && setCookiePopup(JSON.parse(localStorage.getItem('cookiePopup')))

      const preloaderDelay = setTimeout(() => {
            setPreloader(false);
        }, 2000)

        return () => clearTimeout(preloaderDelay);

    }, [])


    return (
        <Layout>
            {preloader && <Preloader/>}
            <Hero/>
            <Intro/>
            <Demographics/>
            <StrategyPreview/>
            <Features/>
            {/*<Plans/>*/}
            <Contact/>
            {
              cookiePopup &&
              <CookiePopup>
                <CookiePopupText>
                  We use cookies to ensure that we give you the best experience on our website. If you continue we’ll assume you’re happy with this.
                </CookiePopupText>
                <CookiePopupButtonWrapper>
                  <CookiePopupButton
                    onClick={handleCookiePopup}
                  >
                    Accept
                  </CookiePopupButton>
                  <CookiePopupButton
                    onClick={handleCookiePopup}
                  >
                    Reject
                  </CookiePopupButton>
                </CookiePopupButtonWrapper>
              </CookiePopup>
            }
        </Layout>
    );
}

export default Landing;
